var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "filterForm",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.filterForm,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder:
                                "订单编号、用户名称、手机号码、收货地收件人姓名、收件人联系方式",
                            },
                            model: {
                              value: _vm.filterForm.keyword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filterForm,
                                  "keyword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "filterForm.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "支付方式：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.filterForm.payType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "payType", $$v)
                                },
                                expression: "filterForm.payType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: null, label: "全部" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "微信" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "支付宝" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "支付状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.filterForm.payStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterForm, "payStatus", $$v)
                                },
                                expression: "filterForm.payStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: null, label: "全部" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 0, label: "待支付" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "已支付" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "timeBox",
                          attrs: { label: "支付时间：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              "unlink-panels": "",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              size: "small",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              type: "datetimerange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onchangeTime($event, "payTimeVal")
                              },
                            },
                            model: {
                              value: _vm.payTimeVal,
                              callback: function ($$v) {
                                _vm.payTimeVal = $$v
                              },
                              expression: "payTimeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "timeBox",
                          attrs: { label: "订单生成时间：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              "unlink-panels": "",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              size: "small",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              type: "datetimerange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.orderTimeVal,
                              callback: function ($$v) {
                                _vm.orderTimeVal = $$v
                              },
                              expression: "orderTimeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-search",
                                label: "default",
                                size: "small",
                              },
                              on: { click: _vm.getList },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "batchBtn" },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "#",
                        "before-upload": _vm.beforeFileUpload,
                        "http-request": _vm.batchSendGoodsUpload,
                        "show-file-list": false,
                        "list-type": "text",
                        accept: ".xls, .xlsx",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:order:entityOrder:batchGoods"],
                              expression:
                                "['platform:order:entityOrder:batchGoods']",
                            },
                          ],
                          attrs: {
                            size: "small",
                            loading: _vm.uploadLoading,
                            type: "primary",
                          },
                        },
                        [_vm._v("批量发货")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:order:entityOrder:derive"],
                          expression: "['platform:order:entityOrder:derive']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.exportList },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData.data, size: "small" },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          "min-width": "50",
                          fixed: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderNo",
                          label: "订单编号",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: "用户名称",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "phone",
                          label: "手机号码",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "totalAmount",
                          label: "订单费用",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: "收货地址",
                          "min-width": "150",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "receiveUsername",
                          label: "收件人姓名",
                          "min-width": "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "receivePhone",
                          label: "收件人联系方式",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "payStatus",
                          label: "支付状态",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.convertPayStatus(row.payStatus))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "payType",
                          label: "支付方式",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.convertPayType(row.payType))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "payTime",
                          label: "支付时间",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm._f("filterEmpty")(row.payTime))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "订单生成时间",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { fixed: "right", label: "操作", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:order:entityOrder:details",
                                        ],
                                        expression:
                                          "['platform:order:entityOrder:details']",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(row.orderId)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _vm._v(" "),
                                row.status < 4
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:order:entityOrder:cancel",
                                            ],
                                            expression:
                                              "['platform:order:entityOrder:cancel']",
                                          },
                                        ],
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            ;(_vm.orderId = row.orderId),
                                              (_vm.cancelOrderDialogVisible = true)
                                          },
                                        },
                                      },
                                      [_vm._v("取消订单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.status === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:order:entityOrder:goods",
                                            ],
                                            expression:
                                              "['platform:order:entityOrder:goods']",
                                          },
                                        ],
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            ;(_vm.orderId = row.orderId),
                                              (_vm.sendGoodsDialogVisible = true)
                                          },
                                        },
                                      },
                                      [_vm._v("发货")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.filterForm.pageSize,
                  "current-page": _vm.filterForm.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("CancelOrderDialog", {
            attrs: {
              visible: _vm.cancelOrderDialogVisible,
              orderId: _vm.orderId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.cancelOrderDialogVisible = $event
              },
              updateList: _vm.getList,
            },
          }),
          _vm._v(" "),
          _c("SendGoodsDialog", {
            attrs: {
              visible: _vm.sendGoodsDialogVisible,
              orderId: _vm.orderId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.sendGoodsDialogVisible = $event
              },
              updateList: _vm.getList,
            },
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "未批量发货成功的订单",
                width: "50%",
                visible: _vm.errorSendVisible,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.errorSendVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "flex-box" }, [
                _vm.errorSendGoods.finalOrderNoList &&
                _vm.errorSendGoods.finalOrderNoList.length !== 0
                  ? _c(
                      "div",
                      [
                        _c("p", { staticClass: "common-color" }, [
                          _vm._v("订单已完成、已取消："),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.errorSendGoods.finalOrderNoList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("p", { staticClass: "common-margin" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorSendGoods.errorStatusOrderNoList &&
                _vm.errorSendGoods.errorStatusOrderNoList.length !== 0
                  ? _c(
                      "div",
                      [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v("订单未支付："),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.errorSendGoods.errorStatusOrderNoList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("p", { staticClass: "common-margin" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorSendGoods.notFoundOrderNoList &&
                _vm.errorSendGoods.notFoundOrderNoList.length !== 0
                  ? _c(
                      "div",
                      [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v("订单号不存在："),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.errorSendGoods.notFoundOrderNoList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("p", { staticClass: "common-margin" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorSendGoods.paramErrorList &&
                _vm.errorSendGoods.paramErrorList.length !== 0
                  ? _c(
                      "div",
                      [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v("快递信息不完整的订单："),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.errorSendGoods.paramErrorList,
                          function (item, index) {
                            return _c("div", { key: index }, [
                              _c("p", { staticClass: "common-margin" }, [
                                _vm._v(_vm._s(item)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }