"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * formConfig: 表单配置{ label: 标题, value: 内容映射字段（stirng | function） },
 * formData: 表单数据
 * flexCell: 表单每行显示数量
 * el-form组件包含的属性均可配置
 * isUpdate 显示 编辑图标
 */
var _default = exports.default = {
  name: 'index',
  props: {
    formConfig: {
      type: Array,
      require: true
    },
    formData: {
      type: Object,
      require: true
    },
    flexCell: {
      type: Number | String,
      default: 3
    }
  },
  mounted: function mounted() {
    this.setFlexCellStyle();
  },
  methods: {
    isLastRowFull: function isLastRowFull(idx) {
      var lastIdx = this.formConfig.length - 1;
      var totalItems = this.formConfig.length;
      // const itemsPerRow = Math.ceil(totalItems / 3);

      return idx === lastIdx && totalItems % 3 === 1;
    },
    setFlexCellStyle: function setFlexCellStyle() {
      var _this = this;
      var doms = this.$refs.publicDetailForm.$children;
      doms.forEach(function (el) {
        el.$el.style.flex = "0 0 calc((100% / ".concat(_this.flexCell, ") - 10px)");
      });
    },
    updateUserInfo: function updateUserInfo(value) {
      this.$emit('openEidtDialog', value);
    }
  }
};