var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-box" },
    [
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _c(
            "div",
            _vm._l(_vm.buttons, function (item, index) {
              return _c(
                "el-button",
                {
                  key: index,
                  attrs: {
                    size: "small",
                    type: _vm.activeIndex === index ? "primary" : "default",
                  },
                  on: {
                    click: function ($event) {
                      _vm.activeIndex = index
                    },
                  },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.activeIndex == 0
            ? _c(
                "div",
                {
                  class: {
                    "active-height":
                      _vm.activeIndex == 0 && _vm.commentList.length === 0,
                  },
                },
                [
                  _vm.commentList.length !== 0
                    ? _c(
                        "div",
                        _vm._l(_vm.commentList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "comment-list-item" },
                            [
                              _c("div", { staticClass: "nav" }, [
                                _c("img", {
                                  attrs: {
                                    src: item.commentUserAvatar,
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(item.commentUserNickName)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _c("div", {
                                  staticClass: "title",
                                  domProps: { innerHTML: _vm._s(item.content) },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "time-like" }, [
                                  _c("div", { staticClass: "time-box" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(item.createTime)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteComment(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "like-box" }, [
                                    _c("i", { staticClass: "el-icon-thumb" }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.likeCount)),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  item.firstReplyComment
                                    ? _c(
                                        "div",
                                        { staticClass: "orther-comment" },
                                        [
                                          _c("div", { staticClass: "head" }, [
                                            _c("img", {
                                              attrs: {
                                                src: item.firstReplyComment
                                                  .commentUserAvatar,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.firstReplyComment
                                                    .commentUserNickName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "title" }, [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.firstReplyComment.content
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "time-like" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "time-box" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.firstReplyComment
                                                            .createTime
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteComment(
                                                              item.firstReplyComment,
                                                              index,
                                                              "firstReplyComment"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "like-box" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-thumb",
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.firstReplyComment
                                                            .likeCount
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                item.isUnfold
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        item.unfoldList,
                                        function (unfoldItem, ids) {
                                          return _c(
                                            "div",
                                            {
                                              key: ids,
                                              staticClass: "orther-comment",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "head" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: unfoldItem.commentUserAvatar,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        unfoldItem.commentUserNickName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "title" },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        unfoldItem.content
                                                      ),
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "time-like",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "time-box",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                unfoldItem.createTime
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "delete",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteComment(
                                                                      unfoldItem,
                                                                      ids,
                                                                      "unfoldList",
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "like-box",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-thumb",
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                unfoldItem.likeCount
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.unfoldList && item.unfoldList.length !== 0
                                  ? _c("div", { staticClass: "unfold" }, [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !item.isUnfold,
                                              expression: "!item.isUnfold",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              ;(item.isUnfold = !item.isUnfold),
                                                _vm.showReplyTitle(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !item.isUnfold,
                                                  expression: "!item.isUnfold",
                                                },
                                              ],
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "展开" +
                                                    _vm._s(
                                                      item.surplusReplyCommentCount
                                                    ) +
                                                    "条回复"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "ic_down el-icon-arrow-down",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.isShowMore,
                                              expression: "item.isShowMore",
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showMoreComment(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("展开更多回复")]
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "ic_down el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !item.isShowMore &&
                                                item.isEndData,
                                              expression:
                                                "!item.isShowMore && item.isEndData",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetComment(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("收起")]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "ic_down el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/imgs/commentEmpty.png"),
                          },
                        }),
                      ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex == 1
            ? _c(
                "div",
                {
                  class: {
                    "active-height":
                      _vm.activeIndex == 1 && _vm.danmuList.length === 0,
                  },
                },
                [
                  _vm.danmuList.length !== 0
                    ? _c(
                        "div",
                        _vm._l(_vm.danmuList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "like-list-item" },
                            [
                              _c("div", { staticClass: "flex-box" }, [
                                _c("div", { staticClass: "left-box" }, [
                                  _c("div", [_vm._v(_vm._s(item.secondsStr))]),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: item.barrageUserAvatar,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.barrageUserNickname) + "："
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.content),
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "delete-box",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteDanmu(item, index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/imgs/daumEmpty.png"),
                          },
                        }),
                      ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeIndex == 2
            ? _c(
                "div",
                {
                  class: {
                    "active-height":
                      _vm.activeIndex == 2 && _vm.likeList.length === 0,
                  },
                },
                [
                  _vm.likeList.length !== 0
                    ? _c(
                        "div",
                        _vm._l(_vm.likeList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "like-list-item" },
                            [
                              _c("div", { staticClass: "flex-box" }, [
                                _c("div", { staticClass: "left-box" }, [
                                  _c("img", {
                                    attrs: { src: item.avatar, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.nickname) + "点赞了短视频作品"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.secondsStr))]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(_vm._s(item.createTime)),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/imgs/likeEmpty.png"),
                          },
                        }),
                      ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pageSize,
              "current-page": _vm.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "editVideo" }, [
        _c("div", [
          _c("div", { staticClass: "cover-box" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("div", [_vm._v("封面图")]),
              _vm._v(" "),
              _c("div", { staticClass: "right-title" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openInputFile(true)
                      },
                    },
                  },
                  [_vm._v("替换")]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" | ")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(_vm.formData.image)
                      },
                    },
                  },
                  [_vm._v("下载")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticClass: "cover-img",
                attrs: { src: _vm.formData.image, alt: "" },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "video-box" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("div", [_vm._v("短视频")]),
              _vm._v(" "),
              _c("div", { staticClass: "right-title" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openInputFile(false)
                      },
                    },
                  },
                  [_vm._v("替换")]
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" | ")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(_vm.formData.videoUrl)
                      },
                    },
                  },
                  [_vm._v("下载")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "video-box" }, [
              _c("video", {
                staticClass: "video",
                attrs: {
                  src: _vm.formData.videoUrl,
                  controls: "",
                  width: "100%",
                  height: "100%",
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("directUpload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "sonDirectUpload",
        attrs: { limit: 1, "file-list": _vm.coverOrVideoUrl },
        on: {
          "update:fileList": function ($event) {
            _vm.coverOrVideoUrl = $event
          },
          "update:file-list": function ($event) {
            _vm.coverOrVideoUrl = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }