var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", "min-width": "40" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "merchantName",
              label: "所属商户",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "totalPrice",
              label: "订单金额",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              label: "订单商品",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "totalNum", label: "订单数量", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "paid", label: "支付状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.paid ? "已支付" : "未支付")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "payType", label: "支付方式", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterEmpty")(
                            _vm._f("payTypeFilter")(row.payType)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "payTime", label: "支付时间", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("filterEmpty")(row.payTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "订单生成时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "订单状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterEmpty")(
                            _vm._f("payMerchantTypeFilter")(row.status)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "130",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(row.orderNo)
                          },
                        },
                      },
                      [_vm._v(" 详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.storeParams.limit,
          "current-page": _vm.storeParams.page,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.tableData.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }