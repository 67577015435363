var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:message:page:list"],
                  expression: "['platform:message:page:list']",
                },
              ],
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "FilterHeader",
                { ref: "filterHeader", attrs: { formData: _vm.filterForm } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.getList },
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.reset } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:message:add"],
                      expression: "['platform:message:add']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addMsg },
                },
                [_vm._v("新增消息")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "55",
                  fixed: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "消息标题",
                  width: "200",
                  fixed: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sendMethod", label: "下发方式", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(row.sendType === 1 ? "即时发送" : "定时发送")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "发送状态", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.sendStatus === 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未发送"),
                            ])
                          : row.sendStatus === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已发送"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sendTime", label: "发送时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createBy", label: "创建人", width: "160" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sendDate",
                  label: "操作",
                  width: "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:message:detail"],
                                expression: "['platform:message:detail']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(row.id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:message:delete"],
                                expression: "['platform:message:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.pageSize,
              "current-page": _vm.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }