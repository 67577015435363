"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _merchant = require("@/api/merchant");
var _cancelOrderDialog = _interopRequireDefault(require("./cancelOrderDialog"));
var _sendGoodsDialog = _interopRequireDefault(require("./sendGoodsDialog"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'zeroOrder',
  components: {
    CancelOrderDialog: _cancelOrderDialog.default,
    SendGoodsDialog: _sendGoodsDialog.default
  },
  data: function data() {
    return {
      userFrom: {
        page: 1,
        limit: 15,
        paid: '',
        payType: '',
        keyword: '',
        dateLimit: '',
        merchantType: '',
        merchantCategoryId: '',
        status: 'all'
      },
      listLoading: false,
      orderTimeVal: [],
      //订单生成时间
      payTimeVal: [],
      //支付时间
      tableData: {
        data: [],
        total: 0
      },
      multipleSelection: [],
      orderVisible: false,
      goodsVisible: false,
      merchantTypeList: [],
      orderNo: null
    };
  },
  created: function created() {
    this.merchantCategoryList();
  },
  mounted: function mounted() {
    this.reset();
  },
  methods: {
    onchangeTime: function onchangeTime(val, name) {
      this.userFrom[name === 'payTimeVal' ? 'payDateLimit' : 'dateLimit'] = val ? val.join(',') : '';
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.userFrom.page = num ? num : this.userFrom.page;
      (0, _order.orderListApi)(this.userFrom).then(function (res) {
        _this.listLoading = false;
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
      }).catch(function (err) {
        _this.listLoading = false;
        _this.tableData = [];
      });
    },
    merchantCategoryList: function merchantCategoryList() {
      var _this2 = this;
      (0, _merchant.merchantCategoryAllListApi)().then(function (res) {
        _this2.merchantTypeList = res;
      }).catch(function (err) {
        console.log(err);
      });
    },
    reset: function reset() {
      this.userFrom = {
        page: 1,
        limit: 15,
        paid: '',
        payType: '',
        keyword: '',
        dateLimit: '',
        merchantType: '',
        merchantCategoryId: '',
        status: 'all'
      };
      this.orderTimeVal = [];
      this.payTimeVal = [];
      this.getList(1);
    },
    pageChange: function pageChange(val) {
      this.userFrom.page = val;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.pageSize = val;
      this.getList();
    }
  }
};