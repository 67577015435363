var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:user:page:list"],
                      expression: "['platform:user:page:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "ID、昵称、手机号" },
                            model: {
                              value: _vm.userFrom.keyword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userFrom,
                                  "keyword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "userFrom.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邀请人：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "邀请人姓名、手机号" },
                            model: {
                              value: _vm.userFrom.inviteKeyword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.userFrom,
                                  "inviteKeyword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "userFrom.inviteKeyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.userFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userFrom, "status", $$v)
                                },
                                expression: "userFrom.status",
                              },
                            },
                            _vm._l(_vm.statusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "等级：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.delGrade },
                              model: {
                                value: _vm.userFrom.gradeBegin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userFrom, "gradeBegin", $$v)
                                },
                                expression: "userFrom.gradeBegin",
                              },
                            },
                            _vm._l(_vm.leftGradeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _vm.userFrom.gradeBegin != 60
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 5px" } },
                                    [_vm._v("-")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        filterable: "",
                                        clearable: "",
                                      },
                                      on: { focus: _vm.chooseRightGrade },
                                      model: {
                                        value: _vm.userFrom.gradeEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userFrom,
                                            "gradeEnd",
                                            $$v
                                          )
                                        },
                                        expression: "userFrom.gradeEnd",
                                      },
                                    },
                                    _vm._l(
                                      _vm.rightGradeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.value,
                                            label: item.label,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "登录城市：" } },
                        [
                          _c("cityCascade", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              cityprops: _vm.cityprops,
                              value: _vm.cityData,
                              gradeType: true,
                            },
                            on: {
                              provincesValueChang: _vm.provincesValueChang,
                              "update:value": function ($event) {
                                _vm.cityData = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "timeBox",
                          attrs: { label: "注册时间：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              "unlink-panels": "",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册渠道：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.userFrom.registerSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userFrom, "registerSource", $$v)
                                },
                                expression: "userFrom.registerSource",
                              },
                            },
                            _vm._l(_vm.registerList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-search",
                                label: "default",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.addUserVisible = true
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        loading: _vm.exportLoading,
                      },
                      on: { click: _vm.exportExcel },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "#",
                        "before-upload": _vm.beforeFileUpload,
                        "http-request": _vm.importExcelUpload,
                        "show-file-list": false,
                        "list-type": "text",
                        accept: ".xls, .xlsx",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:order:entityOrder:batchGoods"],
                              expression:
                                "['platform:order:entityOrder:batchGoods']",
                            },
                          ],
                          attrs: {
                            size: "small",
                            loading: _vm.uploadLoading,
                            type: "primary",
                          },
                        },
                        [_vm._v("导入用户")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.downLoadTemplate },
                    },
                    [_vm._v("下载导入模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: { size: "small", data: _vm.tableData.data },
              on: { "sort-change": _vm.sortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "min-width": "80",
                  fixed: "",
                  sortable: "custom",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户昵称", "min-width": "125" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.nickname))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号码", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "邀请码", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.inviteCode
                          ? _c("span", [_vm._v(_vm._s(scope.row.inviteCode))])
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上级邀请人", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(scope.row.strongFansName)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "strongFansCount",
                  label: "下级金粉人数",
                  "min-width": "120",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(
                                scope.row.strongFansCount
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "金粉绑定上级时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(scope.row.strongFansTime)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否注销", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.isLogoff ? "是" : "否")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "grade",
                  label: "等级",
                  "min-width": "80",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("NumberFilterEmpty")(scope.row.grade))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "marsAmount",
                  label: "火星余额",
                  "min-width": "100",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(scope.row.marsAmount)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "accumulateRechargeAmount",
                  label: "累计收入火星",
                  "min-width": "120",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(
                                scope.row.accumulateRechargeAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "incomeMarsAmount",
                  label: "累计充值火星额",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(
                                scope.row.incomeMarsAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumptionMarsAmount",
                  label: "累计消费火星",
                  "min-width": "120",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(
                                scope.row.consumptionMarsAmount
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumptionPrice",
                  label: "累计消费金额",
                  "min-width": "120",
                  sortable: "custom",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "city",
                  label: "上次登录城市",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("filterEmpty")(scope.row.city))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "注册时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lastLoginTime",
                  label: "最后一次登录时间",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "用户状态",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.status ? "正常" : "冻结")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:user:detail"],
                                expression: "['platform:user:detail']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: { path: "/user/details/" + scope.row.id },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !scope.row.status
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:user:update"],
                                    expression: "['platform:user:update']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.unfreezeUser(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("解冻")]
                            )
                          : _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:user:update"],
                                    expression: "['platform:user:update']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editUser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("冻结")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60, 600],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddUserDialog", {
        attrs: { visible: _vm.addUserVisible },
        on: {
          "update:visible": function ($event) {
            _vm.addUserVisible = $event
          },
          init: function ($event) {
            return _vm.getList(1)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "冻结用户", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { id: _vm.uid },
                on: {
                  resetForm: function ($event) {
                    _vm.visible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }