var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "pram",
              attrs: {
                model: _vm.pram,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "管理员账号", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "管理员账号", disabled: true },
                    model: {
                      value: _vm.pram.account,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pram,
                          "account",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "pram.account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "管理员姓名", prop: "realName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.type === "password",
                      maxlength: "16",
                      placeholder: "管理员姓名",
                    },
                    model: {
                      value: _vm.pram.realName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pram,
                          "realName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "pram.realName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.type === "password"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原始密码", prop: "oldPassword" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "原始密码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.pram.oldPassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "oldPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.oldPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type === "password"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "新密码",
                        prop: "password",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "新密码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.pram.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pram.password
                ? _c(
                    "el-form-item",
                    { attrs: { label: "确认新密码", prop: "confirmPassword" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "确认新密码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.pram.confirmPassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pram,
                              "confirmPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "pram.confirmPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.checkPermi([
                "platform:login:admin:update",
                "platform:login:admin:update:password",
              ])
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handlerSubmit("pram")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.close("pram")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }