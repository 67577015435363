var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      {
        ref: "publicDetailForm",
        staticClass: "public-detail-form",
        attrs: { model: _vm.formData, inline: "", "label-position": "right" },
      },
      "el-form",
      Object.assign({}, { "label-width": "150px" }, _vm.$attrs),
      false
    ),
    [
      _c(
        "div",
        { staticClass: "flex-wrap" },
        _vm._l(_vm.formConfig, function (item, idx) {
          return _c(
            "div",
            {
              key: idx,
              staticClass: "flex-wrap-item",
              class: { "last-row-full": _vm.isLastRowFull(idx) },
            },
            [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(_vm._s(item.label)),
              ]),
              _vm._v(" "),
              item.value != "image"
                ? _c("div", { staticClass: "item-value" }, [
                    _c("div", { staticClass: "item-value-content" }, [
                      _c("span", { staticClass: "value-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              typeof item.value === "function"
                                ? item.value(_vm.formData)
                                : _vm.formData[item.value] ||
                                    (_vm.formData[item.value] === 0
                                      ? _vm.formData[item.value]
                                      : "-")
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      item.isUpdate
                        ? _c("i", {
                            staticClass: "ic-edit el-icon-edit",
                            on: {
                              click: function ($event) {
                                return _vm.updateUserInfo(item.value)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.value === "image"
                ? _c("el-image", {
                    staticClass: "image",
                    attrs: {
                      src: _vm.formData.image,
                      lazy: true,
                      "preview-src-list": [_vm.formData.image],
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }