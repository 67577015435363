var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          style: { background: _vm.color, borderColor: _vm.color },
          attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
          on: { click: _vm.openDirectUpload },
        },
        [_vm._v("\n\n    上传图片")]
      ),
      _vm._v(" "),
      _c("directUpload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDirectUpload,
            expression: "showDirectUpload",
          },
        ],
        ref: "sonDirectUpload",
        attrs: { isTinymce: true, "file-list": _vm.fileList },
        on: {
          "update:fileList": function ($event) {
            _vm.fileList = $event
          },
          "update:file-list": function ($event) {
            _vm.fileList = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }