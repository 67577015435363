"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FilterHeader',
  props: {
    formData: {
      type: Object,
      require: true
    }
  },
  data: function data() {
    return {
      types: [{
        value: '1',
        label: '普通发布'
      }, {
        value: '2',
        label: '涨粉发布'
      }],
      bloodBars: [{
        value: '0',
        label: '无'
      }, {
        value: '1',
        label: '进行中'
      }, {
        value: '2',
        label: '已截止'
      }, {
        value: '3',
        label: '已结束'
      }],
      status: [{
        value: '1',
        label: '上架中'
      }, {
        value: '2',
        label: '草稿'
      }, {
        value: '3',
        label: '已下架'
      }],
      cityprops: {
        children: 'child',
        label: 'regionName',
        value: 'adCode'
      },
      leftGradeList: [],
      rightGradeList: [],
      timerVal: [],
      cityData: []
    };
  },
  watch: {
    'formData.beginLevel': function formDataBeginLevel(newval) {
      if (newval) {
        this.rightGradeList = [];
        this.formData.endLevel = '';
        for (var i = newval; i < this.leftGradeList.length; i++) {
          this.rightGradeList.push({
            label: i + 1,
            value: i + 1
          });
        }
      }
    }
  },
  mounted: function mounted() {
    this.computedGrade();
  },
  methods: {
    changeTime: function changeTime(val) {
      this.formData.beginTime = val[0];
      this.formData.endTime = val[1];
    },
    //获取城市id
    provincesValueChang: function provincesValueChang(value) {
      var _this = this;
      this.cityData = value;
      ['provinceAdCode', 'cityAdCode', 'districtAdCode'].forEach(function (item, index) {
        if (value.length > 0) {
          _this.formData[item] = value[index];
        } else {
          _this.formData[item] = '';
        }
      });
    },
    computedGrade: function computedGrade() {
      var num = 60;
      for (var i = 0; i < num; i++) {
        this.leftGradeList.push({
          label: i + 1,
          value: i + 1
        });
      }
    },
    chooseRightGrade: function chooseRightGrade() {
      if (this.formData.beginLevel === '') {
        this.$message.warning('请先选择左侧等级');
      }
    },
    delGrade: function delGrade() {
      this.formData.endLevel = '';
      this.rightGradeList = [];
    }
  }
};