"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      showDirectUpload: false,
      loading: false
    };
  },
  watch: {
    fileList: function fileList(newVal) {
      this.$emit('successCBK', newVal);
    }
  },
  methods: {
    // modalPicTap(tit) {
    //   console.log(this.$refs.sonDirectUpload.$refs.directUpload);
    //   const _this = this;
    //   this.$modalUpload(
    //     function (img) {
    //       if (!img) return;
    //       let arr = [];
    //       if (img.length > 10) return this.$message.warning('最多选择10张图片！');
    //       img.map((item) => {
    //         arr.push(item.sattDir);
    //       });
    //       _this.$emit('successCBK', arr);
    //     },
    //     tit,
    //     'content',
    //   );
    // },
    openDirectUpload: function openDirectUpload() {
      var inputElement = this.$refs.sonDirectUpload.$refs.directUpload.$el.querySelector('input[type=file]');
      inputElement.click();
    }
  }
};