var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", { staticClass: "header clearfix" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "div",
              { staticClass: "nav-box" },
              [
                _c("el-page-header", { on: { back: _vm.goBack } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.openPCLink()
                      },
                    },
                  },
                  [_vm._v("登录PC端")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.fatherActiveName,
                  callback: function ($$v) {
                    _vm.fatherActiveName = $$v
                  },
                  expression: "fatherActiveName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "用户信息", name: "first" } },
                  [
                    _c("el-card", { staticClass: "box-card" }, [
                      _c("div", { staticClass: "merchant-info" }, [
                        _c(
                          "div",
                          { staticClass: "merchant-img" },
                          [
                            _c("el-image", {
                              attrs: { lazy: true, src: _vm.detailInfo.avatar },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "ic-edit el-icon-edit",
                              on: {
                                click: function ($event) {
                                  return _vm.updateUserInfo("avatar")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-wrap" },
                          _vm._l(_vm.detailDict, function (item, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass: "flex-wrap-item",
                                class: {
                                  "last-row-full": _vm.isLastRowFull(idx),
                                },
                              },
                              [
                                _c("div", { staticClass: "item-title" }, [
                                  _vm._v(_vm._s(item.label)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item-value" }, [
                                  _c(
                                    "div",
                                    { staticClass: "item-value-content" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "value-text" },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                typeof item.value === "function"
                                                  ? item.value(_vm.detailInfo)
                                                  : _vm.detailInfo[
                                                      item.value
                                                    ] ||
                                                      (_vm.detailInfo[
                                                        item.value
                                                      ] === 0
                                                        ? _vm.detailInfo[
                                                            item.value
                                                          ]
                                                        : "-")
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      item.isUpdate
                                        ? _c("i", {
                                            staticClass: "ic-edit el-icon-edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateUserInfo(
                                                  item.value
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "center" }, [
                      _c("div", { staticClass: "balance" }, [
                        _c("div", [_vm._v("火星余额")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.detailInfo.marsAmount))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "record-title",
                                on: {
                                  click: function ($event) {
                                    ;(_vm.recordVisible = true),
                                      (_vm.records = 1)
                                  },
                                },
                              },
                              [_vm._v("调节记录")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:user:operate:mars"],
                                    expression:
                                      "['platform:user:operate:mars']",
                                  },
                                ],
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog("mars")
                                  },
                                },
                              },
                              [_vm._v("调节余额")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "balance" }, [
                        _c("div", [_vm._v("当前等级")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.detailInfo.grade))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "record-title",
                                on: {
                                  click: function ($event) {
                                    ;(_vm.recordVisible = true),
                                      (_vm.records = 3)
                                  },
                                },
                              },
                              [_vm._v("调节记录")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:userDetail:grade"],
                                    expression: "['platform:userDetail:grade']",
                                  },
                                ],
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.gradeVisible = true
                                  },
                                },
                              },
                              [_vm._v("调节等级")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box_bottom" },
                      [
                        _c(
                          "el-card",
                          { staticClass: "box-card" },
                          [
                            _c(
                              "el-tabs",
                              {
                                model: {
                                  value: _vm.childActiveName,
                                  callback: function ($$v) {
                                    _vm.childActiveName = $$v
                                  },
                                  expression: "childActiveName",
                                },
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的短视频",
                                      name: "first",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "first"
                                      ? _c("ShortVideo")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的收藏",
                                      name: "second",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "second"
                                      ? _c("Collect")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的0元订单",
                                      name: "third",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "third"
                                      ? _c("ZeroOrder")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的小店订单",
                                      name: "fourth",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "fourth"
                                      ? _c("MerchantOrder")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的火星明细",
                                      name: "fiveth",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "fiveth"
                                      ? _c("Marslist", {
                                          attrs: { countItem: _vm.countItem },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "TA的金粉",
                                      name: "seventh",
                                    },
                                  },
                                  [
                                    _vm.childActiveName === "seventh"
                                      ? _c("Fans")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.detailInfo.status
                              ? _c(
                                  "div",
                                  { staticClass: "freeze" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:userDetail:freeze",
                                            ],
                                            expression:
                                              "['platform:userDetail:freeze']",
                                          },
                                        ],
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.freezeVisible = true
                                          },
                                        },
                                      },
                                      [_vm._v("冻结账户")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.detailInfo.idCard
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "认证信息", name: "second" } },
                      [
                        _c("div", { staticClass: "info" }, [
                          _vm._v("身份证信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "merchant-info" }, [
                          _c(
                            "div",
                            { staticClass: "flex-wrap" },
                            _vm._l(_vm.identityInfo, function (item, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "flex-wrap-item" },
                                [
                                  _c("div", { staticClass: "item-title" }, [
                                    _vm._v(_vm._s(item.label)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item-value" }, [
                                    !(item.value == null || item.value === "")
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                typeof item.value === "function"
                                                  ? item.value(_vm.detailInfo)
                                                  : _vm.detailInfo[item.value]
                                              ) +
                                              "\n                  "
                                          ),
                                        ])
                                      : _c("div", [_vm._v("-")]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "info" }, [
                          _vm._v("身份证证件"),
                        ]),
                        _vm._v(" "),
                        _c("el-image", {
                          staticClass: "identity-pic",
                          attrs: {
                            src: _vm.detailInfo.portraitImage,
                            "preview-src-list": [_vm.detailInfo.portraitImage],
                          },
                        }),
                        _vm._v(" "),
                        _c("el-image", {
                          staticClass: "identity-pic",
                          attrs: {
                            src: _vm.detailInfo.nationalEmblemImage,
                            "preview-src-list": [
                              _vm.detailInfo.nationalEmblemImage,
                            ],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "冻结用户",
            visible: _vm.freezeVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.freezeVisible = $event
            },
          },
        },
        [
          _vm.freezeVisible
            ? _c("EditForm", {
                attrs: { id: _vm.uid, isDetail: _vm.isDetail },
                on: {
                  resetForm: function ($event) {
                    _vm.freezeVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("OperateMarsDialog", {
        attrs: {
          visible: _vm.balanceVisible,
          currentInfo: _vm.detailInfo,
          name: _vm.operateName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.balanceVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
      _vm._v(" "),
      _c("OperateLevelDialog", {
        attrs: {
          visible: _vm.gradeVisible,
          currentGrade: _vm.detailInfo.grade,
        },
        on: {
          "update:visible": function ($event) {
            _vm.gradeVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
      _vm._v(" "),
      _c("RecordDialog", {
        attrs: { visible: _vm.recordVisible, records: _vm.records },
        on: {
          "update:visible": function ($event) {
            _vm.recordVisible = $event
          },
          init: _vm.init,
        },
      }),
      _vm._v(" "),
      _c("ChangeNameDialog", {
        attrs: {
          visible: _vm.changeNicknameVisible,
          showCurrent: _vm.showCurrent,
        },
        on: {
          "update:visible": function ($event) {
            _vm.changeNicknameVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
      _vm._v(" "),
      _c("ChangeIntroDialog", {
        attrs: {
          visible: _vm.changeIntroVisible,
          showCurrent: _vm.showCurrent,
        },
        on: {
          "update:visible": function ($event) {
            _vm.changeIntroVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
      _vm._v(" "),
      _c("ChangeStrongFansDialog", {
        attrs: {
          visible: _vm.changeStrongFansVisible,
          strongFansUid: _vm.detailInfo.strongFansUid,
        },
        on: {
          "update:visible": function ($event) {
            _vm.changeStrongFansVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
      _vm._v(" "),
      _c("ChangeAvatarDialog", {
        attrs: {
          visible: _vm.changeAvatarVisible,
          showCurrent: _vm.showAvatarCurrent,
        },
        on: {
          "update:visible": function ($event) {
            _vm.changeAvatarVisible = $event
          },
          init: _vm.getUserInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }