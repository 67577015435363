"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  filters: {
    statusType: function statusType(status) {
      var statusMap = {
        0: '商品佣金收入',
        1: '余额提现手续费'
        // 2: '涨粉发布收入',
        // 3: '直播加热收入',
        // 4: '建群费用收入',
        // 5: '火星提现支出',
        // 6: '余额提现支出',
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      userFrom: {},
      listLoading: false,
      selectedData: [],
      timeVal: [],
      labelLists: [{
        id: 0,
        name: '涨粉发布收入'
      }, {
        id: 1,
        name: '直播加热收入'
      }, {
        id: 2,
        name: '建群费用收入'
      }, {
        id: 5,
        name: '体现手续费收入'
      }],
      tableData: [{
        id: 1,
        type: 1,
        //商品佣金收入
        nowMoney: 88.88,
        tradingObject: 2,
        //商户
        name: '张三',
        payWay: 'alipay',
        //支付宝
        payStatus: 1 //已支付
      }],
      keywords: '',
      labelData: null
    };
  },
  methods: {
    pageChange: function pageChange() {},
    handleSizeChange: function handleSizeChange() {},
    userSearchs: function userSearchs() {},
    reset: function reset() {
      this.labelData = null;
      this.keywords = '';
    },
    onchangeTime: function onchangeTime() {},
    getList: function getList() {}
  }
};