"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'newGift',
  data: function data() {
    return {
      currentTab: '1',
      tabList: [{
        value: '1',
        title: '新人礼'
      }, {
        value: '2',
        title: '生日有礼'
      }],
      formValidate: {
        newPeopleSwitch: false,
        birthdaySwitch: false,
        couponIdList: []
      },
      visibleCoupon: false,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        keywords: ''
      },
      multipleSelection: [],
      couponList: [],
      keyNum: 0,
      proviewImg: require('@/assets/imgs/new_gift_modal.png')
    };
  },
  filters: {
    receiveType: function receiveType(val) {
      var typeObj = {
        1: '手动领取',
        2: '商品赠送券',
        3: '平台自动发放'
      };
      return typeObj[val];
    }
  },
  watch: {
    currentTab: {
      handler: function handler(val) {
        this.getConfig();
      },
      immediate: false,
      deep: true
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:marketing:activity:new:people:present:config'])) this.getConfig();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getTime: function getTime(time) {
      var reg = new RegExp('-', 'g'); //g代表全部
      return time.split(' ')[0].replace(reg, '.').substring(2, 10);
    },
    tabChange: function tabChange(e) {
      this.currentTab = e.name;
    },
    //添加优惠券
    addCoupon: function addCoupon() {
      var _this = this;
      _this.formValidate.couponIdList = [];
      this.$modalCoupon('wu', this.keyNum += 1, this.couponList, function (row) {
        _this.couponList = row;
        _this.couponList.forEach(function (item) {
          _this.formValidate.couponIdList.push(item.id);
        });
      });
    },
    // 优惠券详情
    getConfig: function getConfig() {
      var _this2 = this;
      this.formValidate.couponIdList = [];
      if (this.currentTab === '1') {
        this.listLoading = true;
        (0, _marketing.newPeoplePresentConfigApi)().then(function (res) {
          _this2.$set(_this2, 'couponList', res.couponList ? res.couponList : []);
          _this2.formValidate.newPeopleSwitch = res.newPeopleSwitch;
          _this2.couponList.forEach(function (item) {
            _this2.formValidate.couponIdList.push(item.id);
          });
          _this2.listLoading = false;
        }).catch(function () {
          _this2.listLoading = false;
        });
      } else {
        this.listLoading = true;
        (0, _marketing.birthdayPresentConfigApi)().then(function (res) {
          _this2.$set(_this2, 'couponList', res.couponList ? res.couponList : []);
          _this2.formValidate.birthdaySwitch = res.birthdaySwitch;
          _this2.couponList.forEach(function (item) {
            _this2.formValidate.couponIdList.push(item.id);
          });
          _this2.listLoading = false;
        }).catch(function () {
          _this2.listLoading = false;
        });
      }
    },
    rowStyle: function rowStyle(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      Object.defineProperty(row, 'rowIndex', {
        value: rowIndex,
        writable: true,
        enumerable: false
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _marketing.canSendListApi)(this.tableFrom).then(function (res) {
        _this3.visibleCoupon = true;
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 保存
    confirmEdit: function confirmEdit() {
      var _this4 = this;
      if (this.couponList.filter(function (item) {
        return item.isDel;
      }).length > 0) {
        this.$confirm('当前活动中存在已删除优惠券，提交后会自动删除。', '提示').then(function (result) {
          _this4.formValidate.couponIdList = [];
          _this4.couponList.forEach(function (item) {
            if (!item.isDel) {
              _this4.formValidate.couponIdList.push(item.id);
            }
          });
          _this4.handlerEdit();
        });
      } else {
        this.handlerEdit();
      }
    },
    //保存提交数据
    handlerEdit: function handlerEdit() {
      var _this5 = this;
      if (this.currentTab === '1') {
        (0, _marketing.newPeoplePresentEditApi)(this.formValidate).then(function (res) {
          _this5.$message.success('保存成功');
          _this5.getConfig();
        });
      } else {
        (0, _marketing.birthdayPresentEditApi)(this.formValidate).then(function (res) {
          _this5.$message.success('保存成功');
          _this5.getConfig();
        });
      }
    },
    // 删除
    delItem: function delItem(id, index) {
      var i = this.formValidate.couponIdList.findIndex(function (item) {
        return item == id;
      });
      this.couponList.splice(index, 1);
      this.formValidate.couponIdList.splice(i, 1);
    }
  }
};