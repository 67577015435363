var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "directUpload",
          staticClass: "upLoadPicBox",
          attrs: {
            action: "#",
            multiple: "",
            limit: _vm.limit,
            "file-list": _vm.fileList,
            "show-file-list": _vm.showFileList,
            "http-request": _vm.uploadRequest,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.beforeUpload,
            "on-remove": _vm.handleRemove,
          },
        },
        [
          _c(
            "div",
            { staticClass: "img-box" },
            [
              _vm._l(_vm.uploadedFileList, function (item, index) {
                return _c("div", { key: item.uid, staticClass: "upload" }, [
                  !_vm.showFileList
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            class: [
                              "upload-mask",
                              { "upload-mask-bgColor": true },
                            ],
                            style: {
                              opacity: _vm.hoverIndex === index ? 1 : 0,
                            },
                            attrs: { slot: "trigger" },
                            on: {
                              mouseenter: function ($event) {
                                _vm.hoverIndex = index
                              },
                              mouseleave: function ($event) {
                                _vm.hoverIndex = null
                              },
                            },
                            slot: "trigger",
                          },
                          [
                            _vm.hoverIndex === index
                              ? _c("i", {
                                  staticClass: "el-icon-delete delete-icon",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleRemove(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "upload-img",
                          attrs: { src: item.url },
                        }),
                      ])
                    : _vm._e(),
                ])
              }),
              _vm._v(" "),
              _vm.limit > 1 || (_vm.limit === 1 && !_vm.uploadedFileList.length)
                ? _c("div", { staticClass: "upLoad" }, [
                    _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }