var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "acea-row row-column-around trees-coadd" }, [
    _c(
      "div",
      { staticClass: "scollhide" },
      [
        _c(
          "el-timeline",
          { attrs: { reverse: true } },
          _vm._l(_vm.resultList, function (item, i) {
            return _c("el-timeline-item", { key: i }, [
              _c("p", {
                staticClass: "time",
                domProps: { textContent: _vm._s(item.time) },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "content",
                domProps: { textContent: _vm._s(item.status) },
              }),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }