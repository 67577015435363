var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("el-alert", {
                    attrs: {
                      title:
                        "标题前最多展示1个标签，标题下最多展示3个标签；系统根据标签顺序进行展示",
                      type: "warning",
                      "show-icon": "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      staticStyle: { display: "block", "margin-top": "5px" },
                      attrs: { inline: "" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "待搜索的标签名称",
                            },
                            model: {
                              value: _vm.tableFrom.keywords,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "keywords",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:product:brand:add"],
                          expression: "['platform:product:brand:add']",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("添加标签")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableConfig.listLoading,
                  expression: "tableConfig.listLoading",
                },
              ],
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "tag_id",
                "default-expand-all": false,
                "header-cell-style": { fontWeight: "bold" },
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标签名称",
                  prop: "tagName",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标签说明",
                  prop: "tagNote",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "生效期间", width: "260" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.startTime) +
                            " - " +
                            _vm._s(scope.row.endTime) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "position",
                  label: "商城标题位置",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.position === 0 ? "标题下" : "标题前"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        scope.row.owner > 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "infoBox",
          attrs: {
            visible: _vm.editDataDialogConfig.visible,
            title: _vm.editData.id ? "商品标签编辑" : "商品标签新增",
            direction: "rtl",
            "append-to-body": true,
            "custom-class": "demo-drawer",
            size: "900px",
            wrapperClosable: _vm.editData.id ? false : true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDataDialogConfig, "visible", $event)
            },
            close: function ($event) {
              _vm.editDataDialogConfig.visible = false
            },
          },
        },
        [
          _vm.editDataDialogConfig.visible
            ? _c("edit-tag", {
                attrs: { "edit-data": _vm.editData },
                on: { onCloseHandle: _vm.handleOnEditDiaClosed },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }