"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _addOrEditRatio = _interopRequireDefault(require("./addOrEditRatio.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'System',
  components: {
    AddOrEditRatio: _addOrEditRatio.default
  },
  data: function data() {
    return {
      tableLoading: false,
      tableData: [{
        value: '100001-999999',
        ratio: '10000: 1'
      }, {
        value: '100001-999999',
        ratio: '10000: 1'
      }, {
        value: '100001-999999',
        ratio: '10000: 1'
      }, {
        value: '100001-999999',
        ratio: '10000: 1'
      }, {
        value: '100001-999999',
        ratio: '10000: 1'
      }],
      value: '',
      value2: '',
      value3: '',
      value4: '',
      hotPackage: [{
        value: ''
      }, {
        value: ''
      }, {
        value: ''
      }],
      dialogVisible: false
    };
  },
  mounted: function mounted() {},
  methods: {
    addRatio: function addRatio() {
      this.dialogVisible = true;
    },
    editRatio: function editRatio() {},
    deleteRatio: function deleteRatio() {},
    addHotPackage: function addHotPackage() {
      this.hotPackage.push({
        value: ''
      });
    },
    submit: function submit() {}
  }
};