"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DouhuaPlatformDetail',
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    return {
      formData: {},
      formConfig: [{
        label: '投诉/举报类型',
        value: function value(_ref) {
          var type = _ref.type;
          return type === 1 ? '作品' : '直播间';
        }
      }, {
        label: '被举报用户',
        value: 'tipoffNickname'
      }, {
        label: '被举报账号',
        value: 'tipoffAccount'
      }, {
        label: '作品ID/直播间ID',
        value: 'workId'
      }, {
        label: '投诉/举报内容',
        value: 'complaintsContext'
      }, {
        label: '举报人',
        value: 'nickname'
      }, {
        label: '账号',
        value: 'account'
      }, {
        label: '举报时间',
        value: 'createTime'
      }, {
        label: '备注',
        value: function value(_ref2) {
          var remark = _ref2.remark;
          return remark ? remark : '-';
        }
      }]
    };
  },
  mounted: function mounted() {
    this.formData = localStorage.getItem('reportDetail') ? JSON.parse(localStorage.getItem('reportDetail')) : {};
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    }
  }
};