var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "linkTypeName", label: "类型", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "marAmount", label: "火星额", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "记录时间",
              "min-width": "100",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.marsParams.limit,
          "current-page": _vm.marsParams.page,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.tableData.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }