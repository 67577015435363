var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activateIdx,
                    callback: function ($$v) {
                      _vm.activateIdx = $$v
                    },
                    expression: "activateIdx",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "回放画面", name: "0" } },
                    [
                      _c("div", { staticClass: "live-room-box" }, [
                        _c(
                          "div",
                          { staticClass: "video-name_list" },
                          _vm._l(
                            _vm.formData.videoUrlList,
                            function (item, idx) {
                              return _c(
                                "div",
                                {
                                  key: idx,
                                  staticClass: "border-box",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.changeVideo(idx)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name-box" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.displayId) +
                                          "-" +
                                          _vm._s(idx + 1)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-right icon-right",
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "video-duration" }, [
                                    _vm._v("时长 " + _vm._s(item.duration)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "video-list" }, [
                          _c("video", {
                            staticClass: "live-room",
                            attrs: {
                              src:
                                _vm.formData.videoUrlList &&
                                _vm.formData.videoUrlList[_vm.currentIndex].url,
                              controls: "",
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "live-menu" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.back },
                            },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "直播间数据", name: "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "section-box" },
                        [
                          _c("div", { staticClass: "section-title" }, [
                            _vm._v("直播间数据"),
                          ]),
                          _vm._v(" "),
                          _c("PublicDetailForm", {
                            attrs: {
                              formConfig: _vm.formConfig,
                              formData: _vm.formData,
                              flexCell: "3",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "section-box" },
                        [
                          _c("div", { staticClass: "section-title" }, [
                            _vm._v("直播间订单列表"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.tableData.data,
                                size: "mini",
                                "header-cell-style": { fontWeight: "bold" },
                              },
                            },
                            [
                              _vm._l(_vm.tableData.head, function (item, idx) {
                                return [
                                  !item.custom
                                    ? [
                                        _c("el-table-column", {
                                          key: item.prop + idx,
                                          attrs: {
                                            prop: item.prop,
                                            label: item.label,
                                            align: "center",
                                            width: item.width,
                                            formatter: item.formatter,
                                          },
                                        }),
                                      ]
                                    : [
                                        _c("el-table-column", {
                                          key: item.prop + idx,
                                          attrs: {
                                            label: item.label,
                                            prop: item.prop,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    scope.row.productName
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "demo-image__preview acea-row row-middle",
                                                          },
                                                          [
                                                            _c("el-image", {
                                                              staticClass:
                                                                "mr10",
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                              },
                                                              attrs: {
                                                                src: scope.row
                                                                  .productImage,
                                                                "preview-src-list":
                                                                  [
                                                                    scope.row
                                                                      .productImage,
                                                                  ],
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "info",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scope.row
                                                                      .productName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                ]
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  "min-width": "120",
                                  fixed: "right",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermi",
                                                rawName: "v-hasPermi",
                                                value: [
                                                  "platform:product:reply:delete",
                                                ],
                                                expression:
                                                  "['platform:product:reply:delete']",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDetail(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("详情")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "block" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "page-sizes": [10, 20, 40, 60, 80],
                                  "page-size": _vm.liveOrderParams.limit,
                                  "current-page": _vm.liveOrderParams.page,
                                  layout:
                                    "total, sizes, prev, pager, next, jumper",
                                  total: _vm.tableData.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChange,
                                  "current-change": _vm.pageChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }