var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("商品详情")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig,
              formData: _vm.formData,
              flexCell: "3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h1", { staticClass: "section-title" }, [
            _vm._v("商品规格与库存管理"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.tableBodyList, border: "" },
            },
            [
              _vm._l(_vm.formData.attr, function (item) {
                return _c("el-table-column", {
                  key: item.id,
                  attrs: { prop: item.attrName, label: item.attrName },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row[item.attrName])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "零售价（元）" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.price))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.stock))])]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("商品图集")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-imgs" },
          _vm._l(_vm.formData.sliderImages, function (item, index) {
            return _c("div", { key: index, staticClass: "flex-img-item" }, [
              _c("img", { attrs: { src: item, alt: "" } }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "right" } },
          [
            _vm.checkPermi(["platform:product:force:down"]) &&
            _vm.formData.isShow
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        ;(_vm.offProductVisible = true),
                          (_vm.multipleSelection = [{ id: _vm.formData.id }])
                      },
                    },
                  },
                  [_vm._v("下架")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("downProductDialog", {
        attrs: { visible: _vm.offProductVisible, ids: _vm.multipleSelection },
        on: {
          "update:visible": function ($event) {
            _vm.offProductVisible = $event
          },
          init: _vm.detailInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }