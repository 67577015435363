"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var merchantRouter = {
  path: '/merchant',
  component: _layout.default,
  redirect: '/merchant',
  name: 'Merchant',
  meta: {
    title: '商户',
    icon: 'clipboard'
  },
  children: [{
    path: 'classify',
    name: 'MerchantAlassify',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/classify'));
      });
    },
    meta: {
      title: '商户分类',
      icon: ''
    }
  }, {
    path: 'list',
    name: 'MerchantList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/list'));
      });
    },
    meta: {
      title: '商户列表',
      icon: ''
    }
  }, {
    path: 'system',
    name: 'MerchantAystem',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/system'));
      });
    },
    meta: {
      title: '商户菜单管理',
      icon: ''
    }
  }, {
    path: 'application',
    name: 'MerchantApplication',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/application'));
      });
    },
    meta: {
      title: '商户入驻申请',
      icon: ''
    }
  }, {
    path: 'update',
    name: 'MerchantUpdate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/update'));
      });
    },
    meta: {
      title: '商户信息更新审核',
      icon: ''
    }
  }, {
    path: 'detail/:mid',
    name: 'MerchantDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/detial'));
      });
    },
    meta: {
      title: '商户详情',
      icon: ''
    }
  }, {
    path: 'updateDetail/:mid',
    name: 'MerchantUpdateDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/update/detail'));
      });
    },
    meta: {
      title: '商户信息更新详情',
      icon: ''
    }
  }, {
    path: 'applicationDetail/:mid',
    name: 'MerchantApplicationDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/application/detail'));
      });
    },
    meta: {
      title: '商户入驻申请详情',
      icon: ''
    }
  }, {
    path: 'type',
    name: 'MerchantType',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchant/type'));
      });
    },
    meta: {
      title: '店铺类型',
      icon: 'clipboard'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/merchant/type/list'));
        });
      },
      name: 'MerchantTypeList',
      meta: {
        title: '店铺类型',
        icon: ''
      }
    }]
  }]
};
var _default = exports.default = merchantRouter;