var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增用户",
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "userForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.userForm,
            rules: _vm.userRules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称", prop: "nickName" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.userForm.nickName,
                  callback: function ($$v) {
                    _vm.$set(_vm.userForm, "nickName", $$v)
                  },
                  expression: "userForm.nickName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "登录账户", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "请输入手机号码" },
                model: {
                  value: _vm.userForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.userForm, "phone", $$v)
                  },
                  expression: "userForm.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上级邀请人手机号码", "label-width": "160px" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "请输入手机号码" },
                on: { change: _vm.validateInviterPhone },
                model: {
                  value: _vm.userForm.spreadPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.userForm, "spreadPhone", $$v)
                  },
                  expression: "userForm.spreadPhone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }