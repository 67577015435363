"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formData: {
        alipayAaccount: '128990782228989',
        realName: '张三',
        applyNum: "389753895665",
        payState: 1,
        //已支付
        orderAmount: 12345,
        serialUser: '李四',
        platformMoney: "33.33",
        applyTime: '2023-03-01 10:20:30',
        reject: '金额太少'
      },
      formConfig: [{
        label: '支付宝账号',
        value: 'alipayAaccount'
      }, {
        label: '真实姓名',
        value: 'realName'
      }, {
        label: '申请编号',
        value: 'applyNum'
      }, {
        label: '提现金额',
        value: 'orderAmount'
      }, {
        label: '提现用户',
        value: 'serialUser'
      }, {
        label: '申请时间',
        value: 'applyTime'
      }, {
        label: '订单状态',
        value: function value(_ref) {
          var payState = _ref.payState;
          return payState === 1 ? '已支付' : '未支付';
        }
      }, {
        label: '拒绝原因',
        value: 'reject'
      }]
    };
  },
  methods: {
    reject: function reject() {
      var _this = this;
      this.$confirm('确认拒绝提现吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.$message({
          type: 'success',
          message: '拒绝提现!'
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消拒绝'
        });
      });
    },
    confirm: function confirm() {
      var _this2 = this;
      this.$confirm('确认提现吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$message({
          type: 'success',
          message: '提现成功!'
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '提现失败'
        });
      });
    },
    goBack: function goBack() {
      this.$router.push('/finance/withdrawalManagement');
    }
  }
};