var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawerVisible,
            direction: _vm.direction,
            size: "800px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
          },
        },
        [
          [
            _c(
              "div",
              {
                staticClass: "demo-drawer_title",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c("div", { staticClass: "refund-title mb10" }, [
                  _vm._v("退款单"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "refund-orderNo mb20" }, [
                  _vm._v("退款单号：" + _vm._s(_vm.refundInfo.refundOrderNo)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "font12 refund-price" }, [
                    _c("div", { staticClass: "text666 mb10" }, [
                      _vm._v("退款状态"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "textE93323" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("refundStatusFilter")(
                            _vm.refundInfo.refundStatus
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "font12 refund-price" }, [
                    _c("div", { staticClass: "text666 mb10" }, [
                      _vm._v("退款金额"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text333" }, [
                      _vm._v("￥" + _vm._s(_vm.refundInfo.refundPrice)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "font12 refund-price" }, [
                    _c("div", { staticClass: "text666 mb10" }, [
                      _vm._v("实付金额"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text333" }, [
                      _vm._v("￥" + _vm._s(_vm.refundInfo.payPrice)),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "mt20", staticStyle: { padding: "0 20px" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "退款信息", name: "first" },
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: { label: "订单信息", name: "second" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.activeName === "first"
                  ? _c("div", { staticClass: "flow-path" }, [
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("退款商品"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "detail-centent acea-row row-middle" },
                          [
                            _c(
                              "div",
                              { staticClass: "image mr20" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "60px",
                                    height: "60px",
                                  },
                                  attrs: {
                                    src: _vm.refundInfo.image,
                                    "preview-src-list": [_vm.refundInfo.image],
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text666 mb10 productName line-height-15",
                                },
                                [_vm._v(_vm._s(_vm.refundInfo.productName))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text999" }, [
                                _c("span", { staticClass: "mr50" }, [
                                  _vm._v(_vm._s(_vm.refundInfo.sku)),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "售价：￥" + _vm._s(_vm.refundInfo.price)
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("退款明细"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "detail-centent acea-row" }, [
                          _c("div", { staticStyle: { width: "60%" } }, [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("退款数量："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(_vm._s(_vm.refundInfo.applyRefundNum)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.refundInfo.refundStatus === 2 ||
                                      _vm.refundInfo.refundStatus === 3,
                                    expression:
                                      "refundInfo.refundStatus === 2 || refundInfo.refundStatus === 3",
                                  },
                                ],
                                staticClass: "detail-term",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "detail-infoTitle" },
                                  [_vm._v("退回抵扣积分：")]
                                ),
                                _c("span", { staticClass: "detail-info" }, [
                                  _vm._v(
                                    _vm._s(_vm.refundInfo.refundUseIntegral)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.refundInfo.refundStatus === 2 ||
                                      _vm.refundInfo.refundStatus === 3,
                                    expression:
                                      "refundInfo.refundStatus === 2 || refundInfo.refundStatus === 3",
                                  },
                                ],
                                staticClass: "detail-term",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "detail-infoTitle" },
                                  [_vm._v("退一级佣金：")]
                                ),
                                _c("span", { staticClass: "detail-info" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.refundInfo.refundFirstBrokerageFee
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("预计退款方式："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v("原支付返还"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("购买数量："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(_vm._s(_vm.refundInfo.payNum)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.refundInfo.refundStatus === 2 ||
                                      _vm.refundInfo.refundStatus === 3,
                                    expression:
                                      "refundInfo.refundStatus === 2 || refundInfo.refundStatus === 3",
                                  },
                                ],
                                staticClass: "detail-term",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "detail-infoTitle" },
                                  [_vm._v("收回赠送积分：")]
                                ),
                                _c("span", { staticClass: "detail-info" }, [
                                  _vm._v(
                                    _vm._s(_vm.refundInfo.refundGainIntegral)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.refundInfo.refundStatus === 2 ||
                                      _vm.refundInfo.refundStatus === 3,
                                    expression:
                                      "refundInfo.refundStatus === 2 || refundInfo.refundStatus === 3",
                                  },
                                ],
                                staticClass: "detail-term",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "detail-infoTitle" },
                                  [_vm._v("退二级返佣：")]
                                ),
                                _c("span", { staticClass: "detail-info" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.refundInfo.refundSecondBrokerageFee
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.refundInfo.refundStatus === 2 ||
                                      _vm.refundInfo.refundStatus === 3,
                                    expression:
                                      "refundInfo.refundStatus === 2 || refundInfo.refundStatus === 3",
                                  },
                                ],
                                staticClass: "detail-term",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "detail-infoTitle" },
                                  [_vm._v("退回运费：")]
                                ),
                                _c("span", { staticClass: "detail-info" }, [
                                  _vm._v(
                                    _vm._s(_vm.refundInfo.refundFreightFee)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("退款流程信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "detail-centent acea-row" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-steps",
                                {
                                  attrs: {
                                    direction: "vertical",
                                    active:
                                      this.refundInfo.statusList &&
                                      this.refundInfo.statusList.length,
                                    "finish-status": "success",
                                  },
                                },
                                [
                                  _c(
                                    "el-step",
                                    { attrs: { title: "申请退款-用户" } },
                                    [
                                      _vm.refundInfo.statusList &&
                                      _vm.refundInfo.statusList.length
                                        ? _c(
                                            "template",
                                            { slot: "description" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb10" },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.refundInfo.statusList.filter(
                                                          function (item) {
                                                            return (
                                                              item.changeType ===
                                                              "apply"
                                                            )
                                                          }
                                                        )[0].createTime
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "refundReasonWap",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "acea-row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail-term",
                                                          staticStyle: {
                                                            width: "58%",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "detail-infoTitle",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "退款原因："
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "detail-info",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.refundInfo
                                                                    .refundReasonWap
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail-term",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "detail-infoTitle",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "退货方式："
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "detail-info",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.refundInfo
                                                                    .returnGoodsType ===
                                                                    1
                                                                    ? "快递退回"
                                                                    : _vm
                                                                        .refundInfo
                                                                        .returnGoodsType ===
                                                                      2
                                                                    ? "到店退货"
                                                                    : "不退货"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "detail-term acea-row",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "detail-infoTitle",
                                                        },
                                                        [_vm._v("备注说明：")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail-info",
                                                          staticStyle: {
                                                            width: "600px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "filterEmpty"
                                                                )(
                                                                  _vm.refundInfo
                                                                    .refundReasonWapExplain
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "detail-term",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "acea-row",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "detail-infoTitle",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "退款凭证："
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.refundInfo
                                                            .refundReasonWapImg
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  _vm.refundInfo.refundReasonWapImg.split(
                                                                    ","
                                                                  ),
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "el-image",
                                                                      {
                                                                        key: index,
                                                                        staticClass:
                                                                          "mr10",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                            height:
                                                                              "60px",
                                                                          },
                                                                        attrs: {
                                                                          src: item,
                                                                          "preview-src-list":
                                                                            _vm.refundInfo.refundReasonWapImg.split(
                                                                              ","
                                                                            ),
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _c("div", [
                                                                _vm._v("-"),
                                                              ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "audit"
                                  })
                                    ? _c(
                                        "el-step",
                                        { attrs: { title: "商家审核-商家" } },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "audit"
                                                                )
                                                              }
                                                            ).length
                                                              ? _vm.refundInfo.statusList.filter(
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return (
                                                                      item.changeType ===
                                                                      "audit"
                                                                    )
                                                                  }
                                                                )[0].createTime
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.refundInfo.refundStatus >
                                                  0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "refundReasonWap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-term",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "detail-infoTitle",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "审核结果："
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "detail-info",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .refundInfo
                                                                        .refundStatus ===
                                                                        1 &&
                                                                        _vm
                                                                          .refundInfo
                                                                          .statusList
                                                                          .length ===
                                                                          2
                                                                        ? "拒绝退款"
                                                                        : "同意退款"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.refundInfo
                                                            .refundStatus ===
                                                            1 &&
                                                          _vm.refundInfo
                                                            .statusList
                                                            .length === 2
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "拒绝原因："
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "filterEmpty"
                                                                          )(
                                                                            _vm
                                                                              .refundInfo
                                                                              .refundReason
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.refundInfo
                                                            .returnGoodsType ===
                                                            1 &&
                                                          _vm.refundInfo
                                                            .refundStatus !== 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-infoTitle",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "退货地址："
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-info",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .refundInfo
                                                                              .receiverAddressDetail
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("div", [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-info",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .refundInfo
                                                                              .receiver
                                                                          ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .refundInfo
                                                                                .receiverPhone
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "returning"
                                  })
                                    ? _c(
                                        "el-step",
                                        {
                                          attrs: { title: "商品退回信息-用户" },
                                        },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "returning"
                                                                )
                                                              }
                                                            )[0].createTime
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.refundInfo
                                                    .returnGoodsType === 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "refundReasonWap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "acea-row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                  staticStyle: {
                                                                    width:
                                                                      "58%",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "物流公司："
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .refundInfo
                                                                            .expressName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "物流单号："
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .refundInfo
                                                                            .trackingNumber
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-term",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "detail-infoTitle",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "联系电话："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "detail-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .refundInfo
                                                                          .telephone
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.refundInfo
                                                    .returnGoodsType === 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "refundReasonWap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-term",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "detail-infoTitle",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "联系电话："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "detail-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .refundInfo
                                                                          .telephone
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "receiving"
                                  })
                                    ? _c(
                                        "el-step",
                                        {
                                          attrs: { title: "商家确认收货-商家" },
                                        },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "receiving"
                                                                )
                                                              }
                                                            ).length
                                                              ? _vm.refundInfo.statusList.filter(
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return (
                                                                      item.changeType ===
                                                                      "receiving"
                                                                    )
                                                                  }
                                                                )[0].createTime
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "rejectionGoods"
                                  })
                                    ? _c(
                                        "el-step",
                                        {
                                          attrs: { title: "商品拒绝收货-商家" },
                                        },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "rejectionGoods"
                                                                )
                                                              }
                                                            )[0].createTime
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.refundInfo.refundStatus >
                                                  0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "refundReasonWap",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-term",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "detail-infoTitle",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "审核结果："
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "detail-info",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .refundInfo
                                                                        .refundStatus ===
                                                                        1
                                                                        ? "拒绝退款"
                                                                        : "同意退款"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.refundInfo
                                                            .refundStatus === 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "拒绝原因："
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "detail-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "filterEmpty"
                                                                          )(
                                                                            _vm
                                                                              .refundInfo
                                                                              .refundReason
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.refundInfo
                                                            .returnGoodsType ===
                                                            1 &&
                                                          _vm.refundInfo
                                                            .refundStatus !== 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "detail-term",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-infoTitle",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "退货地址："
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-info",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .refundInfo
                                                                              .receiverAddressDetail
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("div", [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "detail-infoTitle",
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "detail-info",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .refundInfo
                                                                              .receiver
                                                                          ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .refundInfo
                                                                                .receiverPhone
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus === 6 &&
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "revoke"
                                  })
                                    ? _c(
                                        "el-step",
                                        { attrs: { title: "已撤销" } },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "revoke"
                                                                )
                                                              }
                                                            ).length
                                                              ? _vm.refundInfo.statusList.filter(
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return (
                                                                      item.changeType ===
                                                                      "revoke"
                                                                    )
                                                                  }
                                                                )[0].createTime
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "compulsory"
                                  })
                                    ? _c(
                                        "el-step",
                                        {
                                          attrs: { title: "平台强制退款成功" },
                                        },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "compulsory"
                                                                )
                                                              }
                                                            ).length
                                                              ? _vm.refundInfo.statusList.filter(
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return (
                                                                      item.changeType ===
                                                                      "compulsory"
                                                                    )
                                                                  }
                                                                )[0].createTime
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  _vm.refundInfo.statusList &&
                                  _vm.refundInfo.statusList.length &&
                                  _vm.refundInfo.statusList.find(function (
                                    item
                                  ) {
                                    return item.changeType === "refund"
                                  })
                                    ? _c(
                                        "el-step",
                                        { attrs: { title: "退款成功" } },
                                        [
                                          _vm.refundInfo.statusList &&
                                          _vm.refundInfo.statusList.length
                                            ? _c(
                                                "template",
                                                { slot: "description" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb10" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.refundInfo.statusList.filter(
                                                              function (item) {
                                                                return (
                                                                  item.changeType ===
                                                                  "refund"
                                                                )
                                                              }
                                                            ).length
                                                              ? _vm.refundInfo.statusList.filter(
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return (
                                                                      item.changeType ===
                                                                      "refund"
                                                                    )
                                                                  }
                                                                )[0].createTime
                                                              : ""
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 2 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  _vm.refundInfo.refundStatus === 0
                                    ? _c("el-step", {
                                        attrs: { title: "商家审核-商家" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 2 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  _vm.refundInfo.afterSalesType === 2 &&
                                  (_vm.refundInfo.refundStatus === 0 ||
                                    _vm.refundInfo.refundStatus === 4)
                                    ? _c("el-step", {
                                        attrs: { title: "商品退回信息-用户" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 2 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  _vm.refundInfo.afterSalesType === 2 &&
                                  (_vm.refundInfo.refundStatus === 0 ||
                                    _vm.refundInfo.refundStatus === 4 ||
                                    _vm.refundInfo.refundStatus === 5)
                                    ? _c("el-step", {
                                        attrs: { title: "商家确认收货-商家" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.refundInfo.refundStatus !== 1 &&
                                  _vm.refundInfo.refundStatus !== 6 &&
                                  (_vm.refundInfo.refundStatus === 0 ||
                                    _vm.refundInfo.refundStatus === 2 ||
                                    _vm.refundInfo.refundStatus === 4 ||
                                    _vm.refundInfo.refundStatus === 5)
                                    ? _c("el-step", {
                                        attrs: { title: "退款成功" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "detail-section",
                          staticStyle: { "margin-top": "-30px" },
                        },
                        [
                          _c("div", { staticClass: "detail-title" }, [
                            _vm._v("备注信息"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "detail-term acea-row line-height-15",
                            },
                            [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("平台备注："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "detail-info productName" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        _vm.refundInfo.platformRemark
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "detail-term acea-row line-height-15",
                            },
                            [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("商家备注："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "detail-info productName" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("filterEmpty")(
                                          _vm.refundInfo.merRemark
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeName === "second"
                  ? _c("div", { staticClass: "flow-path" }, [
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("用户信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticStyle: { width: "60%" } }, [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v(" 用户昵称："),
                              ]),
                              _c("span", { staticClass: "detail-info mr5" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.nickname)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "mr5" }, [
                                _vm._v(" | "),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.refundInfo.orderInfoVo.uid)),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "detail-term" }, [
                            _c("span", { staticClass: "detail-infoTitle" }, [
                              _vm._v("用户电话："),
                            ]),
                            _c("span", { staticClass: "detail-info" }, [
                              _vm._v(_vm._s(_vm.refundInfo.orderInfoVo.phone)),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("配送信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticStyle: { width: "60%" } }, [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("配送方式："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.refundInfo.orderInfoVo.shippingType ===
                                      1
                                      ? "快递"
                                      : "门店自提"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("收货电话："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      _vm.refundInfo.orderInfoVo.userPhone
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("收货人："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      _vm.refundInfo.orderInfoVo.realName
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("收货地址："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      _vm.refundInfo.orderInfoVo.userAddress
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("订单信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticStyle: { width: "60%" } }, [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("订单号："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.orderNo)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("商品总数："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.totalNum)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("支付状态："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.refundInfo.orderInfoVo.paid
                                      ? "已支付"
                                      : "未支付"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("创建时间："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.createTime)
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "detail-term" }, [
                              _c(
                                "span",
                                { staticClass: "detail-infoTitle textE93323" },
                                [_vm._v("订单状态：")]
                              ),
                              _c(
                                "span",
                                { staticClass: "detail-info textE93323" },
                                [
                                  _vm.refundInfo.orderInfoVo.refundStatus === 3
                                    ? _c("span", [_vm._v("已退款")])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("orderStatusFilter")(
                                              _vm.refundInfo.orderInfoVo.status
                                            )
                                          )
                                        ),
                                      ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("已发货数量："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.deliveryNum)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("支付方式："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("payTypeFilter")(
                                      _vm.refundInfo.orderInfoVo.payType
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("支付时间："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.payTime)
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("订单明细"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticStyle: { width: "60%" } }, [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("商品总价："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      _vm.refundInfo.orderInfoVo.proTotalPrice
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("平台优惠金额："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      _vm.refundInfo.orderInfoVo.platCouponPrice
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("积分抵扣金额："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      _vm.refundInfo.orderInfoVo.integralPrice
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("扣除抵扣积分："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(_vm.refundInfo.orderInfoVo.useIntegral)
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("实际支付："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(_vm.refundInfo.orderInfoVo.payPrice)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("商家优惠金额："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      _vm.refundInfo.orderInfoVo.merCouponPrice
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("支付邮费："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      _vm.refundInfo.orderInfoVo.payPostage
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-term" }, [
                              _c("span", { staticClass: "detail-infoTitle" }, [
                                _vm._v("赠送积分："),
                              ]),
                              _c("span", { staticClass: "detail-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.refundInfo.orderInfoVo.gainIntegral
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-section" }, [
                        _c("div", { staticClass: "detail-title" }, [
                          _vm._v("备注信息"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "detail-term acea-row line-height-15",
                          },
                          [
                            _c("span", { staticClass: "detail-infoTitle" }, [
                              _vm._v("用户备注："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "detail-info productName" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      _vm.refundInfo.orderInfoVo.userRemark
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "detail-term acea-row line-height-15",
                          },
                          [
                            _c("span", { staticClass: "detail-infoTitle" }, [
                              _vm._v("商家备注："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "detail-info productName" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(
                                      _vm.refundInfo.orderInfoVo.merchantRemark
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "demo-drawer__footer" }, [
                  _c("div", { staticClass: "from-foot-btn drawer_fix" }, [
                    _vm.checkPermi([
                      "platform:refund:order:compulsory:refund",
                    ]) &&
                    (_vm.refundInfo.refundStatus === 0 ||
                      _vm.refundInfo.refundStatus === 5)
                      ? _c(
                          "div",
                          { staticClass: "acea-row row-center-wrapper" },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "debounceClick",
                                    rawName: "v-debounceClick",
                                    value: function () {
                                      _vm.handlerCompulsoryReturn(
                                        _vm.refundInfo
                                      )
                                    },
                                    expression:
                                      "\n                  () => {\n                    handlerCompulsoryReturn(refundInfo);\n                  }\n                ",
                                  },
                                ],
                                staticClass: "mr20",
                                attrs: { type: "primary" },
                              },
                              [_vm._v("强制退款")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }