var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "hasPermi",
          rawName: "v-hasPermi",
          value: ["platform:system:systemSet:page"],
          expression: "['platform:system:systemSet:page']",
        },
      ],
      staticClass: "divBox relative",
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("h1", { staticClass: "section-title" }, [
                _vm._v("短视频完播奖励比例设置"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:system:systemSet:addRatio"],
                      expression: "['platform:system:systemSet:addRatio']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.addRatio },
                },
                [_vm._v("新增比例设置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                size: "small",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "value", label: "火星奖励值" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ratio", label: "奖励比例" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:system:systemSet:editRatio"],
                                expression:
                                  "['platform:system:systemSet:editRatio']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editRatio(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "platform:system:systemSet:deleteRatio",
                                ],
                                expression:
                                  "['platform:system:systemSet:deleteRatio']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRatio(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card mgtp-16" }, [
        _c("h1", { staticClass: "section-title" }, [
          _vm._v("短视频完播奖励上限设置"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "time-limit mgtp-16" },
          [
            _vm._v("\n      完播奖励时限\n      "),
            _c("el-input-number", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
            _vm._v("\n      小时\n    "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card mgtp-16" }, [
        _c("h1", { staticClass: "section-title" }, [
          _vm._v("涨粉发布佣金比例设置"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "send-ratio mgtp-16" },
          [
            _vm._v("\n      涨粉发布佣金\n      "),
            _c("el-input-number", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.value2,
                callback: function ($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2",
              },
            }),
            _vm._v("\n      %\n    "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card mgtp-16" }, [
        _c("h1", { staticClass: "section-title" }, [_vm._v("加热套餐设置")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "promot-ratio mgtp-16" },
          [
            _c("span", [_vm._v("推广人数比例")]),
            _vm._v(" "),
            _c("span", [_vm._v("10火星=")]),
            _vm._v(" "),
            _c("el-input-number", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.value3,
                callback: function ($$v) {
                  _vm.value3 = $$v
                },
                expression: "value3",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("推广人数或")]),
            _vm._v(" "),
            _c("el-input-number", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.value4,
                callback: function ($$v) {
                  _vm.value4 = $$v
                },
                expression: "value4",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("直播间")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "hot-package" },
          [
            _c("span", [_vm._v("加热套餐")]),
            _vm._v(" "),
            _vm._l(_vm.hotPackage, function (item, idx) {
              return _c(
                "el-input",
                {
                  key: idx,
                  attrs: { size: "small", placeholder: "请输入" },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("火星")])],
                2
              )
            }),
            _vm._v(" "),
            _c("el-button", {
              attrs: { icon: "el-icon-plus", size: "small" },
              on: { click: _vm.addHotPackage },
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submit-btn mgtp-16" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:system:systemSet:edit"],
                  expression: "['platform:system:systemSet:edit']",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddOrEditRatio", {
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }