"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      userFrom: {},
      listLoading: false,
      selectedData: [],
      timeVal: [],
      selectionList: [],
      labelLists: [{
        id: 1,
        name: '涨粉发布'
      }, {
        id: 2,
        name: '帮TA涨粉'
      }, {
        id: 3,
        name: '直播加热'
      }, {
        id: 4,
        name: '建群费用'
      }, {
        id: 5,
        name: '付费入场'
      }, {
        id: 6,
        name: '直播间福袋支出'
      }, {
        id: 7,
        name: '打赏送礼消费'
      }],
      tableData: [{
        id: 1,
        type: 1,
        money: 88.88,
        nowNumer: '1234543255565555',
        payStatus: 1,
        payTime: '2024-1-2',
        payNum: '22222222333',
        payUser: '张三',
        nowObject: 1,
        nowUser: '嘻嘻有限公司',
        nowMoney: '22.22',
        payWay: 'bank'
      }],
      registerTypeList: [],
      pickerOptions: this.$timeOptions,
      keywords: '',
      labelData: null
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    pageChange: function pageChange() {},
    handleSizeChange: function handleSizeChange() {},
    userSearchs: function userSearchs() {},
    reset: function reset() {
      this.userFrom = {
        keywords: '',
        payType: null,
        payCount: '',
        payState: null,
        registerType: null,
        beginTime: '',
        endTime: ''
      };
      this.timeVal = [];
      this.getList();
    },
    onchangeTime: function onchangeTime(val) {
      this.userFrom.beginTime = val ? val[0] : '';
      this.userFrom.endTime = val ? val[1] : '';
    },
    getList: function getList() {},
    deriveBtn: function deriveBtn() {},
    detailBtn: function detailBtn(id) {
      this.$router.push({
        name: 'userDiamondTransactionDetails',
        params: {
          id: id
        }
      });
    }
  }
};