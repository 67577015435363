var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      ref: "dialog",
      attrs: {
        title: "基数记录",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            "highlight-current-row": true,
            "header-cell-style": { fontWeight: "bold" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "150" },
          }),
          _vm._v(" "),
          _vm._l(_vm.tableHead, function (item, idx) {
            return [
              _c("el-table-column", {
                key: item.prop + idx,
                attrs: {
                  align: "center",
                  label: item.label,
                  prop: item.prop,
                  "min-width": "150",
                },
              }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticClass: "pagenation",
        attrs: {
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.pagePamars.pageSize,
          "current-page": _vm.pagePamars.pageNum,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.tableData.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }