var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.multiple
      ? _c(
          "div",
          { staticClass: "acea-row" },
          [
            _vm._l(_vm.showImageList, function (item, index) {
              return _c("div", {
                key: index,
                staticClass: "pictrue",
                attrs: { draggable: "false" },
              })
            }),
            _vm._v(" "),
            _c("directUpload", {
              attrs: {
                model: "img",
                limit: 20,
                "file-list": _vm.showImageList,
              },
              on: {
                "update:fileList": function ($event) {
                  _vm.showImageList = $event
                },
                "update:file-list": function ($event) {
                  _vm.showImageList = $event
                },
              },
            }),
          ],
          2
        )
      : _c(
          "div",
          {
            staticClass: "upLoadPicBox",
            on: {
              click: function ($event) {
                return _vm.modalPicTap("1")
              },
            },
          },
          [
            _c("directUpload", {
              attrs: { model: "img", limit: 1, "file-list": _vm.showImage },
              on: {
                "update:fileList": function ($event) {
                  _vm.showImage = $event
                },
                "update:file-list": function ($event) {
                  _vm.showImage = $event
                },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }