var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "productForm",
              attrs: {
                model: _vm.productForm,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { maxlength: "40", placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.productForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "name", $$v)
                      },
                      expression: "productForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品副标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { maxlength: "40", placeholder: "请输入商品副标题" },
                    model: {
                      value: _vm.productForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "title", $$v)
                      },
                      expression: "productForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品封面", prop: "coverPhoto" } },
                [
                  _c("directUpload", {
                    attrs: {
                      model: "img",
                      limit: 1,
                      "file-list": _vm.productForm.coverPhoto,
                    },
                    on: {
                      "update:fileList": function ($event) {
                        return _vm.$set(_vm.productForm, "coverPhoto", $event)
                      },
                      "update:file-list": function ($event) {
                        return _vm.$set(_vm.productForm, "coverPhoto", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片", prop: "sliderPhoto" } },
                [
                  _c("directUpload", {
                    attrs: {
                      model: "img",
                      limit: 9,
                      "file-list": _vm.productForm.sliderPhoto,
                    },
                    on: {
                      "update:fileList": function ($event) {
                        return _vm.$set(_vm.productForm, "sliderPhoto", $event)
                      },
                      "update:file-list": function ($event) {
                        return _vm.$set(_vm.productForm, "sliderPhoto", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品售价", prop: "price" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { type: "number", placeholder: "请输入商品售价" },
                    on: {
                      change: function ($event) {
                        return _vm.formatNum($event, "price")
                      },
                    },
                    model: {
                      value: _vm.productForm.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "price", $$v)
                      },
                      expression: "productForm.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "成本价", prop: "cost" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { type: "number", placeholder: "请输入成本价" },
                    on: {
                      change: function ($event) {
                        return _vm.formatNum($event, "cost")
                      },
                    },
                    model: {
                      value: _vm.productForm.cost,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "cost", $$v)
                      },
                      expression: "productForm.cost",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品库存", prop: "stock" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { type: "number", placeholder: "请输入商品库存" },
                    on: {
                      change: function ($event) {
                        return _vm.checkStock($event, "商品库存", "stock")
                      },
                    },
                    model: {
                      value: _vm.productForm.stock,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "stock", $$v)
                      },
                      expression: "productForm.stock",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "成团人数", prop: "people" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { type: "number", placeholder: "请输入成团人数" },
                    on: {
                      change: function ($event) {
                        return _vm.checkStock($event, "成团人数", "people")
                      },
                    },
                    model: {
                      value: _vm.productForm.people,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "people", $$v)
                      },
                      expression: "productForm.people",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "运费", prop: "freightFee" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { placeholder: "请输入运费" },
                    on: {
                      change: function ($event) {
                        return _vm.formatNum($event, "freightFee")
                      },
                    },
                    model: {
                      value: _vm.productForm.freightFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.productForm, "freightFee", $$v)
                      },
                      expression: "productForm.freightFee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品详情", prop: "description" } },
                [
                  _c("Tinymce", {
                    attrs: { placeholder: "请输入商品详情" },
                    model: {
                      value: _vm.productForm.description,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.productForm,
                          "description",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "productForm.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "left-btn",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveProduct },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }