var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "更换头像",
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.form, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "avatar-box",
              attrs: {
                prop: "avatar",
                rules: [
                  { required: true, message: "请上传头像", trigger: "blur" },
                ],
                label: "",
              },
            },
            [
              _c("directUpload", {
                attrs: { model: "img", limit: 1, "file-list": _vm.form.avatar },
                on: {
                  "update:fileList": function ($event) {
                    return _vm.$set(_vm.form, "avatar", $event)
                  },
                  "update:file-list": function ($event) {
                    return _vm.$set(_vm.form, "avatar", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("支持格式：.png .jpg")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }