var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c("el-page-header", { on: { back: _vm.goBack } }),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.commonActiveName,
            callback: function ($$v) {
              _vm.commonActiveName = $$v
            },
            expression: "commonActiveName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "info" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("PublicDetailForm", {
                    attrs: {
                      formConfig: _vm.formConfig,
                      formData: _vm.formData,
                      flexCell: "3",
                    },
                    on: { openEidtDialog: _vm.openEidtDialog },
                  }),
                  _vm._v(" "),
                  _vm.formData.publishType === 2
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "line" }),
                          _vm._v(" "),
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.handleClick },
                              model: {
                                value: _vm.activeName,
                                callback: function ($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName",
                              },
                            },
                            [
                              _c("el-tab-pane", {
                                attrs: { label: "涨粉充值记录", name: "first" },
                              }),
                              _vm._v(" "),
                              _c("el-tab-pane", {
                                attrs: {
                                  label: "作品奖励调节记录",
                                  name: "second",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData.data,
                                size: "mini",
                                "highlight-current-row": true,
                                "header-cell-style": { fontWeight: "bold" },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "150",
                                },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.tableHead, function (item, idx) {
                                return [
                                  [
                                    _c("el-table-column", {
                                      key: item.prop + idx,
                                      attrs: {
                                        align: "center",
                                        label: item.label,
                                        prop: item.prop,
                                        "min-width": "150",
                                      },
                                    }),
                                  ],
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("el-pagination", {
                            staticClass: "pagenation",
                            attrs: {
                              "page-sizes": [20, 40, 60, 80],
                              "page-size": _vm.pagePamars.pageSize,
                              "current-page": _vm.pagePamars.pageNum,
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.tableData.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.pageChange,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "fotter" },
                    [
                      _vm.formData.shelveStatus === 1
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["platform:shortVideo:ban"],
                                  expression: "['platform:shortVideo:ban']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.downVideo },
                            },
                            [_vm._v("下架视频")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formData.publishType === 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:shortVideo:addReward"],
                                      expression:
                                        "['platform:shortVideo:addReward']",
                                    },
                                  ],
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      _vm.addRewardVisible = true
                                    },
                                  },
                                },
                                [_vm._v("增加作品奖励")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:shortVideo:endReward"],
                                      expression:
                                        "['platform:shortVideo:endReward']",
                                    },
                                  ],
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.stopReward },
                                },
                                [_vm._v("终止奖励")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "短视频编辑", name: "edit" } },
            [
              _c("EditVideo", {
                attrs: { formData: _vm.formData, name: _vm.commonActiveName },
                on: { init: _vm.getInfo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddRewardDialog", {
        attrs: { value: _vm.addRewardVisible, id: _vm.id },
        on: {
          "update:value": function ($event) {
            _vm.addRewardVisible = $event
          },
          init: _vm.updateInfo,
        },
      }),
      _vm._v(" "),
      _c("DownVideoDialog", {
        attrs: {
          visible: _vm.offVideoVisible,
          singleId: Number(_vm.id),
          isSignOffVideo: true,
        },
        on: {
          "update:visible": function ($event) {
            _vm.offVideoVisible = $event
          },
          init: _vm.getInfo,
        },
      }),
      _vm._v(" "),
      _c("ChangeVideoIntroDialog", {
        attrs: { visible: _vm.changeVideoVisible, showCurrent: _vm.formData },
        on: {
          "update:visible": function ($event) {
            _vm.changeVideoVisible = $event
          },
          init: _vm.getInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }