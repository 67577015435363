var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("el-card", { staticClass: "box-card mb20" }, [
            _c("div", { staticClass: "acea-row row-center row-middle" }, [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-arrow-left" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pl10" }, [_vm._v("返回")]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "f-s-18 f-w-500 ml32 add_title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.isEdit && !_vm.isCopy
                        ? "编辑优惠券"
                        : !_vm.isCopy
                        ? "添加优惠券"
                        : "复制优惠券"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-card",
            { attrs: { "body-style": { padding: "0 20px 20px" } } },
            [
              _vm.tabList.length > 0
                ? _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    _vm._l(_vm.tabList, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: { name: item.value, label: item.title },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "formValidate",
                  staticClass: "formValidate",
                  attrs: {
                    rules: _vm.ruleValidate,
                    model: _vm.formValidate,
                    "label-width": "150px",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === "1",
                          expression: "currentTab === '1'",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              size: "small",
                              placeholder: "请输入优惠券名称",
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.formValidate.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "name", $$v)
                              },
                              expression: "formValidate.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券面值(元)：", prop: "money" } },
                        [
                          _c(
                            "el-input-number",
                            {
                              staticClass: "selWidthNum",
                              attrs: {
                                type: "number",
                                size: "small",
                                disabled: _vm.isEdit && !_vm.isCopy,
                                max: 9999,
                                step: 1,
                                "step-strictly": "",
                                placeholder: "请输入优惠券面值",
                              },
                              model: {
                                value: _vm.formValidate.money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "money", $$v)
                                },
                                expression: "formValidate.money",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffix_text",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("元")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "使用门槛(元)：", prop: "minPrice" },
                        },
                        [
                          _c(
                            "el-input-number",
                            {
                              staticClass: "selWidthNum",
                              attrs: {
                                size: "small",
                                disabled: _vm.isEdit && !_vm.isCopy,
                                max: 999999,
                                step: 1,
                                "step-strictly": "",
                                placeholder: "请输入使用门槛",
                              },
                              model: {
                                value: _vm.formValidate.minPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "minPrice", $$v)
                                },
                                expression: "formValidate.minPrice",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffix_text",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v("元")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "desc mt10" }, [
                            _vm._v(
                              "填写优惠券的最低消费金额，使用门槛为0时指无门槛"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取方式：", prop: "receiveType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isEdit && !_vm.isCopy },
                              model: {
                                value: _vm.formValidate.receiveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "receiveType", $$v)
                                },
                                expression: "formValidate.receiveType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("手动领取"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("平台自动发放"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "desc mt10" }, [
                            _vm._v(
                              "\n              1. 用户手动领取指用户需要在移动端的领券中心领取优惠券；"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              2. 平台自动发放指其他营销活动可选择此类型优惠券，用户满足活动条件后直接提示发放 ；\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.receiveType === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "领取时间：",
                                prop: "isTimeReceive",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.isEdit && !_vm.isCopy,
                                  },
                                  model: {
                                    value: _vm.formValidate.isTimeReceive,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "isTimeReceive",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.isTimeReceive",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("时间段"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("不限时"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.isTimeReceive &&
                      _vm.formValidate.receiveType === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "collectionTime" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "selWidth",
                                attrs: {
                                  size: "small",
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "picker-options":
                                    _vm.pickerOptionsForEditCoupon,
                                  align: "right",
                                },
                                on: { change: _vm.onChangeCollectionTime },
                                model: {
                                  value: _vm.formValidate.collectionTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "collectionTime",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.collectionTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v("优惠券可以在此时间范围之内领取"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "使用有效期：", prop: "isFixedTime" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.isFixedTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isFixedTime", $$v)
                                },
                                expression: "formValidate.isFixedTime",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("天数"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("时间段"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "desc mt10" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  !_vm.isEdit || _vm.isCopy
                                    ? ""
                                    : "优惠券编辑后，之前已经领取的优惠券使用有效期不会改变"
                                ) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.formValidate.isFixedTime
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "day" } },
                            [
                              _c(
                                "el-input-number",
                                {
                                  staticClass: "selWidthNum",
                                  attrs: {
                                    size: "small",
                                    placeholder: "请输入天数",
                                    max: 999,
                                    step: 1,
                                    "step-strictly": "",
                                  },
                                  model: {
                                    value: _vm.formValidate.day,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "day", $$v)
                                    },
                                    expression: "formValidate.day",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "suffix_text",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [_vm._v("天")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "领取之后多少天之后失效，失效的优惠券将不能使用"
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.isFixedTime
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "validityTime" } },
                            [
                              _c("el-date-picker", {
                                staticClass: "selWidth",
                                attrs: {
                                  size: "small",
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  align: "right",
                                  "picker-options":
                                    _vm.pickerOptionsForEditCoupon,
                                },
                                on: { change: _vm.onChangeValidityTime },
                                model: {
                                  value: _vm.formValidate.validityTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "validityTime",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.validityTime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              !_vm.isEdit || _vm.isCopy
                                ? "发布数量(张)："
                                : "增加发布数量(张)：",
                            prop: "isLimited",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isEdit && !_vm.isCopy },
                              model: {
                                value: _vm.formValidate.isLimited,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isLimited", $$v)
                                },
                                expression: "formValidate.isLimited",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("限量"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("不限量"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.isLimited
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "total" } },
                            [
                              !_vm.isEdit || _vm.isCopy
                                ? _c(
                                    "el-input-number",
                                    {
                                      staticClass: "selWidthNum",
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入优惠券数量",
                                        max: 999999,
                                        step: 1,
                                        "step-strictly": "",
                                      },
                                      model: {
                                        value: _vm.formValidate.total,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "total",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.total",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "suffix_text",
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("张")]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-input-number",
                                    {
                                      staticClass: "selWidthNum",
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入优惠券数量",
                                        max: 999999,
                                        step: 1,
                                        "step-strictly": "",
                                      },
                                      model: {
                                        value: _vm.formValidate.num,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formValidate, "num", $$v)
                                        },
                                        expression: "formValidate.num",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "suffix_text",
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("张")]
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      !_vm.isEdit || _vm.isCopy
                                        ? "填写优惠券的发放数量"
                                        : "编辑时，填写优惠券增加的数量；例如：新增时填写2张优惠券，编辑时填写1，则编辑后总共发布3张优惠券"
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "重复领取：", prop: "isRepeated" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.isRepeated,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isRepeated", $$v)
                                },
                                expression: "formValidate.isRepeated",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("不可重复"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("可重复"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formValidate.receiveType === 1
                            ? _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n              可重复领取，若用户领取该优惠券且使用过后，可以再次领取；"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              不可重复领取，若用户领取该优惠券无论是否使用，都不可再次领取\n            "
                                ),
                              ])
                            : _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n              可重复领取，若多个营销活动赠送同一优惠券，一个用户可领取多张；"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              不可重复领取，若多个营销活动赠送同一优惠券，一个用户只能领取1张\n            "
                                ),
                              ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启:" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              width: 56,
                              disabled: _vm.isEdit && !_vm.isCopy,
                              "active-text": "开启",
                              "inactive-text": "关闭",
                            },
                            model: {
                              value: _vm.formValidate.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "status", $$v)
                              },
                              expression: "formValidate.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === "2",
                          expression: "currentTab === '2'",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isEdit && !_vm.isCopy },
                              model: {
                                value: _vm.formValidate.category,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "category", $$v)
                                },
                                expression: "formValidate.category",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("通用"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v("品类"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("商品"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 5 } }, [
                                _vm._v("品牌"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 6 } }, [
                                _vm._v("跨店"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.category == 4
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择分类:",
                                span: 24,
                                "label-width": "80px",
                              },
                            },
                            [
                              _c("el-cascader", {
                                ref: "cascader",
                                staticClass: "selWidth",
                                attrs: {
                                  clearable: "",
                                  options: _vm.adminProductClassify,
                                  props: _vm.categoryProps,
                                },
                                model: {
                                  value: _vm.linkedData,
                                  callback: function ($$v) {
                                    _vm.linkedData = $$v
                                  },
                                  expression: "linkedData",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.category == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.addGoods },
                                },
                                [_vm._v("添加商品")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    disabled: !_vm.multipleSelection.length,
                                  },
                                  on: { click: _vm.batchDel },
                                },
                                [_vm._v("批量删除")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.category == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.listLoading,
                                      expression: "listLoading",
                                    },
                                  ],
                                  ref: "tableList",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.tableData.data,
                                    size: "mini",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    "select-all": _vm.selectAll,
                                    select: _vm.selectOne,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "55" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "id",
                                      label: "ID",
                                      width: "55",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "商品图",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview",
                                                },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src: scope.row.image,
                                                      "preview-src-list": [
                                                        scope.row.image,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1825039654
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      prop: "name",
                                      label: "商品名称",
                                      "min-width": "200",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "price",
                                      label: "售价",
                                      "min-width": "90",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "stock",
                                      label: "库存",
                                      "min-width": "70",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      "min-width": "140",
                                      fixed: "right",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelete(
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1945863928
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.category == 5
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择品牌:",
                                span: 24,
                                "label-width": "100px",
                                prop: "proBrandList",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    loading: _vm.loading,
                                    remote: "",
                                    placeholder: "请选择品牌",
                                  },
                                  model: {
                                    value: _vm.proBrandList,
                                    callback: function ($$v) {
                                      _vm.proBrandList = $$v
                                    },
                                    expression: "proBrandList",
                                  },
                                },
                                _vm._l(_vm.productBrand, function (v, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: {
                                      label: v.name,
                                      value: v.id,
                                      disabled: !v.isShow,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.category == 6
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择商户:",
                                span: 24,
                                "label-width": "100px",
                                prop: "merIds",
                              },
                            },
                            [
                              _c("merchant-name", {
                                attrs: {
                                  multiple: true,
                                  merIdChecked: _vm.merIds,
                                },
                                on: { getMerId: _vm.getMerId },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "fixed-card box-card", attrs: { "dis-hover": "" } },
        [
          _c(
            "div",
            { staticClass: "acea-row row-center-wrapper" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "1",
                      expression: "currentTab === '1'",
                    },
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.submitForm("formValidate")
                      },
                      expression:
                        "\n          () => {\n            submitForm('formValidate');\n          }\n        ",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                },
                [_vm._v("下一步")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "2",
                      expression: "currentTab === '2'",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.currentTab = "1"
                    },
                  },
                },
                [_vm._v("上一步")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentTab === "2" &&
                        _vm.checkPermi([
                          "platform:coupon:add",
                          "platform:coupon:update",
                        ]),
                      expression:
                        "currentTab === '2' && checkPermi(['platform:coupon:add', 'platform:coupon:update'])",
                    },
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.save("formValidate")
                      },
                      expression:
                        "\n          () => {\n            save('formValidate');\n          }\n        ",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }