var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "奖励比例设置",
        visible: _vm.value,
        width: "700px",
        "before-close": _vm.hanleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "火星奖励值", prop: "value1" } },
            [
              _c(
                "div",
                { staticClass: "flex-algin-center" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.value1,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value1", $$v)
                      },
                      expression: "formData.value1",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("--")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.formData.value2,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value2", $$v)
                      },
                      expression: "formData.value2",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "奖励比例", prop: "value3" } }, [
            _c(
              "div",
              { staticClass: "flex-algin-center" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.formData.value3,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "value3", $$v)
                    },
                    expression: "formData.value3",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(":")]),
                _vm._v(" "),
                _c("el-input", {
                  model: {
                    value: _vm.formData.value4,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "value4", $$v)
                    },
                    expression: "formData.value4",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tips" }, [
        _vm._v("当火星奖励值达到当前范围，则触发当前比例奖励"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          "例如火星奖励值为1-100，奖励比例10:1，表示前100火星奖励值内，10个完播奖励1火星"
        ),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.hanleClose } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }