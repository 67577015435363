"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AddOrEditRatio',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      formData: {
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      },
      rules: {
        value1: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        value3: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    hanleClose: function hanleClose() {
      this.$emit('input', false);
    }
  }
};