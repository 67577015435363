"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  filters: {},
  data: function data() {
    return {
      userFrom: {},
      listLoading: false,
      selectedData: [],
      timeVal: [],
      labelLists: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '待处理'
      }, {
        id: 2,
        name: '已提现'
      }, {
        id: 3,
        name: '已拒绝'
      }],
      tableData: [{
        id: 1,
        number: 11,
        nowMoney: 88.88,
        nowUser: "张三",
        applyTime: '2020-12-31',
        applyWay: 'bank',
        applyStatus: 1
      }],
      pickerOptions: this.$timeOptions,
      keywords: '',
      labelData: null,
      totalvalue: 20
    };
  },
  methods: {
    pageChange: function pageChange() {},
    handleSizeChange: function handleSizeChange() {},
    userSearchs: function userSearchs() {},
    reset: function reset() {},
    onchangeTime: function onchangeTime() {},
    getList: function getList() {},
    detailBtn: function detailBtn() {}
  }
};