var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "div",
          { staticClass: "divBox" },
          [
            _c(
              "el-card",
              { staticClass: "box-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.tableFrom,
                      "label-width": "100px",
                      size: "small",
                      inline: true,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "搜索：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "分类名称" },
                          model: {
                            value: _vm.tableFrom.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keywords", $$v)
                            },
                            expression: "tableFrom.keywords",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "创建时间：" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.tableFrom.dateLimit,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                            },
                            expression: "tableFrom.dateLimit",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handlerGetTreeList },
                          },
                          [_vm._v("搜索")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.reset } }, [
                          _vm._v("重置"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:product:category:add"],
                        expression: "['platform:product:category:add']",
                      },
                    ],
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.handleAddMenu },
                  },
                  [_vm._v("新增分类\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "table",
                    attrs: { size: "mini", data: _vm.dataList },
                  },
                  [
                    _vm._l(_vm.tableHead, function (item, idx) {
                      return [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            align: "center",
                            label: item.label,
                            prop: item.prop,
                            "min-width": "150",
                          },
                        }),
                      ]
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "200",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "platform:product:category:update",
                                      ],
                                      expression:
                                        "['platform:product:category:update']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditMenu(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "platform:product:category:delete",
                                      ],
                                      expression:
                                        "['platform:product:category:delete']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除\n              ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0 ? "新增分类" : "编辑分类",
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleClassForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.classForm,
                rules: _vm.classRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.classForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.classForm, "name", $$v)
                      },
                      expression: "classForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "排序" },
                    model: {
                      value: _vm.classForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.classForm, "sort", $$v)
                      },
                      expression: "classForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        loading: _vm.loadingBtn,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitCategory("ruleClassForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "确认" +
                          _vm._s(
                            _vm.editDialogConfig.isCreate === 0
                              ? "新增"
                              : "修改"
                          )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.editDialogConfig.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }