var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "div",
          { staticClass: "divBox" },
          [
            _c(
              "el-card",
              { staticClass: "box-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.tableFrom,
                      "label-width": "100px",
                      size: "small",
                      inline: true,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "搜索：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "模板名称" },
                          model: {
                            value: _vm.tableFrom.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keywords", $$v)
                            },
                            expression: "tableFrom.keywords",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.reset } }, [
                          _vm._v("重置"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["platform:shipping:templates:save"],
                        expression: "['platform:shipping:templates:save']",
                      },
                    ],
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleAdd("add")
                      },
                    },
                  },
                  [_vm._v("新增模板 ")]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "table",
                    attrs: { size: "mini", data: _vm.tableData.data },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "id", label: "ID", "min-width": "60" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "模板名称",
                        "min-width": "180",
                        prop: "name",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "排序",
                        "min-width": "100",
                        prop: "sort",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "包邮类型",
                        "min-width": "100",
                        prop: "appoint",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("appointTypeFilter")(scope.row.appoint)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        "min-width": "150",
                        prop: "createTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "200",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "platform:shipping:templates:info",
                                      ],
                                      expression:
                                        "['platform:shipping:templates:info']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("详情\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "platform:shipping:templates:delete",
                                      ],
                                      expression:
                                        "['platform:shipping:templates:delete']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除\n              ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("CreatTemplates", {
              ref: "addTemplates",
              on: { getList: _vm.getList },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }