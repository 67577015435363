"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _merchant = require("@/api/merchant");
var _permission = require("@/utils/permission");
var _info = _interopRequireDefault(require("./info"));
var _previewBox = _interopRequireDefault(require("./previewBox"));
var _downProductDialog = _interopRequireDefault(require("./components/downProductDialog.vue"));
var _auditDialog = _interopRequireDefault(require("./auditDialog.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// const headerName = [
//   {
//     name: '出售中商品',
//     type: 1,
//   },
//   {
//     name: '仓库中商品',
//     type: 2,
//   },
//   {
//     name: '待审核商品',
//     type: 6,
//   },
//   {
//     name: '审核未通过商品',
//     type: 7,
//   },
// ];
var _default = exports.default = {
  name: 'ProductList',
  components: {
    infoFrom: _info.default,
    previewBox: _previewBox.default,
    downProductDialog: _downProductDialog.default,
    AuditDialog: _auditDialog.default
  },
  data: function data() {
    var _this2 = this;
    return {
      componentKey: 0,
      productId: 0,
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: false
      },
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      categoryIdData: [],
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        date: '',
        page: 1,
        limit: 10,
        categoryId: null,
        keywords: '',
        type: null,
        // cateId: null,
        merId: null,
        dateLimit: ''
      },
      categoryList: [],
      merCateList: [],
      dialogVisible: false,
      card_select_show: false,
      checkAll: false,
      // checkedCities: ['ID', '商品图', '商品名称', '商品售价', '商户名称', '商户类别', '销量', '库存', '审核状态'],
      // columnData: ['ID', '商品图', '商品名称', '商品售价', '商户名称', '商户类别', '销量', '库存', '审核状态'],
      isIndeterminate: true,
      merchantList: [],
      loading: false,
      total: 0,
      keyNum: 0,
      multipleSelection: [],
      tableHead: [{
        label: '商品ID',
        prop: 'id',
        custom: false
      }, {
        label: '商品封面',
        prop: 'image',
        custom: true
      }, {
        label: '商品名称',
        prop: 'name',
        custom: false
      }, {
        label: '商品分类',
        prop: 'categoryName',
        custom: false
      }, {
        label: '所属商家',
        prop: 'merchantName',
        custom: false
      }, {
        label: '入驻分类',
        prop: 'merchantCategoryName',
        custom: false
      }, {
        label: '商品价格',
        prop: 'price',
        custom: false
      }, {
        label: '销量',
        prop: 'sales',
        custom: false
      }, {
        label: '库存',
        prop: 'stock',
        custom: false
      }, {
        label: '审核状态',
        prop: 'auditStatus',
        custom: false,
        formatter: function formatter(row, column, cellValue) {
          return _this2.auditFilter(cellValue);
        }
      }, {
        label: '状态',
        prop: 'isShow',
        custom: false,
        formatter: function formatter(row, column, cellValue) {
          return cellValue === true ? '已上架' : '未上架';
        }
      }, {
        label: '创建时间',
        prop: 'createTime',
        custom: false
      }],
      shopCategoryListData: [],
      timeVal: [],
      offProductVisible: false,
      currentRowId: null //审核ID
    };
  },
  activated: function activated() {},
  mounted: function mounted() {
    // if (checkPermi(['platform:product:tabs:headers'])) this.goodHeade();
    // if (!localStorage.getItem('adminProductClassify')) this.$store.dispatch('product/getAdminProductClassify');
    if ((0, _permission.checkPermi)(['platform:product:page:list', 'platform:product:category:list', 'platform:merchant:category:list'])) {
      this.getList();
      this.shopCategoryList(); //商品分类
      this.merchantCategoryList(); //商家入驻分类
    }
    // this.checkedCities = this.$cache.local.has('goods_stroge')
    //   ? this.$cache.local.getJSON('goods_stroge')
    //   : this.checkedCities;
  },
  computed: {
    // ...mapGetters(['adminProductClassify', 'frontDomain']),
    heightBoxs: function heightBoxs() {
      var _this3 = this;
      this.$nextTick(function () {
        // 页面渲染完成后的回调
        return Number(_this3.$refs.headerBox.offsetHeight) - Number(document.documentElement.clientHeight);
      });
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    auditFilter: function auditFilter(status) {
      var statusMap = {
        0: '无需审核',
        1: '待审核',
        2: '审核通过',
        3: '审核不通过'
      };
      return statusMap[status] || '未知状态';
    },
    timeChange: function timeChange(val) {
      this.tableFrom.dateLimit = val ? val.join(',') : '';
    },
    ishttp: function ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
    },
    getMerId: function getMerId(id) {
      this.tableFrom.merId = id;
      this.subSuccess();
    },
    //商品分类列表
    shopCategoryList: function shopCategoryList() {
      var _this4 = this;
      (0, _product.productCategoryAllListApi)().then(function (res) {
        _this4.shopCategoryListData = res;
      });
    },
    //入驻分类
    merchantCategoryList: function merchantCategoryList() {
      var _this5 = this;
      (0, _merchant.merchantCategoryAllListApi)().then(function (res) {
        _this5.merchantList = res;
      });
    },
    batchOff: function batchOff() {
      if (this.multipleSelection.length === 0) return this.$message.warning('请选择商品');
      this.offProductVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleAudit: function handleAudit(id) {
      this.currentRowId = id;
      this.dialogVisible = true;
    },
    handleEdit: function handleEdit(row) {
      var _this = this;
      this.$modalParserFrom('编辑商品', '平台商品编辑', 1, {
        ficti: row.ficti || '0',
        id: row.id,
        rank: row.rank
      }, function (formValue) {
        _this.submit(formValue, row.id);
      }, this.keyNum += 5);
    },
    submit: function submit(formValue, id) {
      var _this6 = this;
      var data = {
        id: id,
        ficti: formValue.ficti,
        rank: formValue.rank
      };
      (0, _product.updateProductApi)(data).then(function (res) {
        _this6.$message.success('操作成功');
        _this6.$msgbox.close();
        _this6.getList(1);
      }).catch(function () {
        _this6.loading = false;
      });
    },
    toDetail: function toDetail(row) {
      this.$router.push({
        name: 'managementDetail',
        params: {
          id: row.id
        }
      });
    },
    // 导出
    exportsList: function exportsList() {
      (0, _product.productExcelApi)(this.tableFrom);
    },
    // 获取商品表单头数量
    // goodHeade() {
    //   productHeadersApi()
    //     .then((res) => {
    //       res.map((item, index) => {
    //         if (item.type === headerName[index].type) item.name = headerName[index].name;
    //       });
    //       this.headeNum = res;
    //     })
    //     .catch((res) => {
    //       this.$message.error(res.message);
    //     });
    // },
    // 列表
    getList: function getList(num) {
      var _this7 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.listLoading = true;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.list;
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
      }).catch(function (res) {
        _this7.listLoading = false;
      });
    },
    reset: function reset() {
      this.tableFrom = {
        date: '',
        page: 1,
        limit: 20,
        categoryId: null,
        keywords: null,
        type: null,
        // cateId: null,
        merId: null
      };
      this.timeVal = [];
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this8 = this;
      this.$modalSure("\u5220\u9664 id \u4E3A ".concat(id, " \u7684\u5546\u54C1")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        (0, _product.productDeleteApi)(id, deleteFlag).then(function () {
          _this8.$message.success('删除成功');
          _this8.getList();
          // this.goodHeade();
        });
      });
    }
  }
};