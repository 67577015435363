var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container mt-1" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商品名称、副标题" },
                            model: {
                              value: _vm.tableFrom.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "content", $$v)
                              },
                              expression: "tableFrom.content",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.tableFrom.isShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "isShow", $$v)
                                },
                                expression: "tableFrom.isShow",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "已上架", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "已下架", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              align: "right",
                              "unlink-panels": "",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.toDetail("add")
                    },
                  },
                },
                [_vm._v(" 新增商品 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBatch("batchUp")
                    },
                  },
                },
                [_vm._v(" 批量上架 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleBatch },
                },
                [_vm._v(" 批量下架 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.exportList },
                },
                [_vm._v(" 导出列表 ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                key: "2",
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "index", width: "55", label: "序号" },
              }),
              _vm._v(" "),
              _vm._l(_vm.tableHead, function (item, idx) {
                return [
                  item.custom
                    ? [
                        item.label === "商品封面"
                          ? _c("el-table-column", {
                              key: item.prop + idx,
                              attrs: {
                                label: item.label,
                                "min-width": "80",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src: scope.row.coverPhoto,
                                                "preview-src-list": [
                                                  scope.row.coverPhoto,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.label === "状态"
                          ? _c("el-table-column", {
                              key: item.prop + idx,
                              attrs: {
                                label: item.label,
                                "min-width": "80",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        item.label === "状态"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.isShow === 1
                                                        ? "已上架"
                                                        : "已下架"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    : [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            align: "center",
                            label: item.label,
                            prop: item.prop,
                            "min-width": "150",
                          },
                        }),
                      ],
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.isShow === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBatch(
                                      "batchUp",
                                      true,
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("上架")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBatch(
                                      "batchOff",
                                      true,
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }