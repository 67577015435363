var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "nav-info" }, [
          _c("div", { staticClass: "left-info" }, [
            _c("div", { staticClass: "left-info son-box" }, [
              _c("div", [
                _vm._v(_vm._s(_vm.aggregateData.nowMarsAmount) + "火星"),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("当前奖池")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "left-info son-box" }, [
              _c("div", [
                _vm._v(_vm._s(_vm.aggregateData.todayMarsAmount) + "火星"),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("今日奖池累计收入")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "left-info son-box" }, [
              _c("div", [
                _vm._v(_vm._s(_vm.aggregateData.todayRewardsMars) + "火星"),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("今日累计产生奖励")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add-btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPrizePool } },
                [_vm._v("增加奖池基数")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "record",
                  on: {
                    click: function ($event) {
                      _vm.recordVisible = true
                    },
                  },
                },
                [_vm._v("记录")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "视频列表", name: "first" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "作者列表", name: "second" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "150" },
              }),
              _vm._v(" "),
              _vm._l(_vm.tableHead, function (item, idx) {
                return [
                  !item.render
                    ? [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            align: "center",
                            label: item.label,
                            prop: item.prop,
                            "min-width": "150",
                            formatter: item.formatter,
                          },
                        }),
                      ]
                    : item.render === "imageRenderer"
                    ? [
                        _c("el-table-column", {
                          key: item.label,
                          attrs: { label: item.label, "min-width": "150" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row[item.prop]
                                      ? _c("el-image", {
                                          attrs: {
                                            src: scope.row[item.prop],
                                            "preview-src-list": [
                                              scope.row[item.prop],
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm.activeName === "first"
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "260",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:shortVideo:detail"],
                                      expression:
                                        "['platform:shortVideo:detail']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(row.id)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:shortVideo:addReward"],
                                      expression:
                                        "['platform:shortVideo:addReward']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addReward(row.id)
                                    },
                                  },
                                },
                                [_vm._v("增加奖励")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:shortVideo:endReward"],
                                      expression:
                                        "['platform:shortVideo:endReward']",
                                    },
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.stopReward(row.id)
                                    },
                                  },
                                },
                                [_vm._v("终止奖励")]
                              ),
                              _vm._v(" "),
                              row.shelveStatus !== 1
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["platform:shortVideo:ban"],
                                          expression:
                                            "['platform:shortVideo:ban']",
                                        },
                                      ],
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleBatch(
                                            "batchUp",
                                            row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("上架")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.shelveStatus !== 3
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["platform:shortVideo:ban"],
                                          expression:
                                            "['platform:shortVideo:ban']",
                                        },
                                      ],
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleBatch(
                                            "batchOff",
                                            row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下架")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      567020616
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "pagenation",
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pagePamars.pageSize,
              "current-page": _vm.pagePamars.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddRewardDialog", {
        attrs: {
          value: _vm.addRewardVisible,
          id: _vm.id,
          isAddprizePool: _vm.isAddprizePool,
        },
        on: {
          "update:value": function ($event) {
            _vm.addRewardVisible = $event
          },
          init: _vm.updateInfo,
        },
      }),
      _vm._v(" "),
      _c("RecordDialog", {
        attrs: { visible: _vm.recordVisible },
        on: {
          "update:visible": function ($event) {
            _vm.recordVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }