var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "发货",
        visible: _vm.defaultVisible,
        width: "500px",
        "before-close": _vm.close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.defaultVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.formData, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物流单号:",
                prop: "deliveryOrderCode",
                rules: [
                  {
                    required: true,
                    message: "请输入物流单号",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                on: { input: _vm.handleInput },
                model: {
                  value: _vm.formData.deliveryOrderCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "deliveryOrderCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.deliveryOrderCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "物流公司:", prop: "transportCompanyName" } },
            [
              _c(
                "el-input",
                {
                  attrs: { autocomplete: "off", disabled: "" },
                  model: {
                    value: _vm.formData.transportCompanyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "transportCompanyName", $$v)
                    },
                    expression: "formData.transportCompanyName",
                  },
                },
                [
                  _vm.inputLoading
                    ? _c("i", {
                        staticClass: "el-input__icon el-icon-loading",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }