"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AddOrEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editInfo: {
      type: Object,
      default: {}
    }
  },
  watch: {
    // todo 直接把编辑信息赋值给ruleForm即可，现在数据不对暂不使用
    editInfo: function editInfo(newVal) {
      this.ruleForm = newVal;
      this.type = newVal.isIndividual ? 1 : 2;
    }
  },
  data: function data() {
    return {
      rules: {
        name: [{
          required: true,
          message: '请填写分类名称',
          trigger: 'change'
        }]
      },
      type: 1,
      ruleForm: {
        name: '',
        // status: 1,
        handlingFee: 0,
        isEnterprise: false,
        isIndividual: true,
        id: ''
      }
    };
  },
  methods: {
    typeChange: function typeChange(newValue) {
      this.ruleForm.isIndividual = newValue === 1 ? true : false;
      this.ruleForm.isEnterprise = newValue === 2 ? true : false;
      console.log(this.ruleForm);
    },
    submit: function submit() {
      var _this = this;
      !this.ruleForm.id ? merchant.merchantCategoryAddApi(this.ruleForm).then(function (res) {
        _this.$message.success('操作成功');
        _this.$store.commit('merchant/SET_MerchantClassify', []);
        _this.handleClose('新增');
      }).catch(function () {
        _this.loading = false;
      }) : merchant.merchantCategoryUpdateApi(this.ruleForm).then(function (res) {
        _this.$message.success('操作成功');
        _this.$store.commit('merchant/SET_MerchantClassify', []);
        _this.handleClose('修改');
      }).catch(function () {
        _this.loading = false;
      });
    },
    handleClose: function handleClose(val) {
      var _this2 = this;
      this.$emit('input', false);
      if (val) this.$emit('clearEditInfo');
      this.$nextTick(function () {
        _this2.$refs.ruleForm.clearValidate();
      });
    }
  }
};