var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      ref: "menu",
      attrs: { "default-active": _vm.activeMenu, mode: "horizontal" },
      on: { select: _vm.handleSelect },
    },
    [
      _vm._l(_vm.topMenus, function (item, index) {
        return [
          index < _vm.visibleNumber
            ? _c(
                "el-menu-item",
                {
                  key: index,
                  style: { "--theme": _vm.theme },
                  attrs: { index: item.path },
                },
                [
                  _vm.navIcon
                    ? _c("i", { class: "el-icon-" + item.extra })
                    : _vm._e(),
                  _vm._v(_vm._s(item.name) + "\n    "),
                ]
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.topMenus.length > _vm.visibleNumber
        ? _c(
            "el-submenu",
            { style: { "--theme": _vm.theme }, attrs: { index: "more" } },
            [
              _c("template", { slot: "title" }, [_vm._v("更多菜单")]),
              _vm._v(" "),
              _vm._l(_vm.hiddenMenus, function (i, idx) {
                return [
                  _c("el-menu-item", { key: idx, attrs: { index: i.path } }, [
                    _vm.navIcon
                      ? _c("i", { class: "el-icon-" + i.extra })
                      : _vm._e(),
                    _vm._v(_vm._s(i.name) + "\n      "),
                  ]),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }