"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.giftAddApi = giftAddApi;
exports.giftBanApi = giftBanApi;
exports.giftDelApi = giftDelApi;
exports.giftDetailApi = giftDetailApi;
exports.giftListApi = giftListApi;
exports.giftUpdateApi = giftUpdateApi;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 礼物管理 列表
 * @param params
 */
function giftListApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/giftPageByAdmin',
    method: 'get',
    params: params
  });
}

/**
 * 礼物管理 禁用
 * @param data
 */
function giftBanApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/changeGiftStatus',
    method: 'post',
    data: data
  });
}

/**
 * 礼物管理 删除
 * @param data
 */
function giftDelApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/delete',
    method: 'post',
    data: data
  });
}

/**
 * 礼物管理 新增
 * @param data
 */
function giftAddApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/add',
    method: 'post',
    data: data
  });
}

/**
 * 礼物管理 详情
 * @param data
 */
function giftDetailApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/giftDetailByAdmin',
    method: 'get',
    params: params
  });
}

/**
 *
 * 礼物管理 修改
 * @param data
 * @returns {*}
 */
function giftUpdateApi(data) {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/gift/update",
    method: 'post',
    data: data
  });
}