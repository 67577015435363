var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "live-room-box" }, [
              _c("div", { staticClass: "live-room" }, [
                _c("iframe", {
                  ref: "liveRoomIframe",
                  staticClass: "live-room-iframe",
                  attrs: { width: "400", height: "100%", src: _vm.iframeSrc },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "live-menu" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "close",
                    attrs: { type: "primary", plain: "" },
                    on: {
                      click: function ($event) {
                        _vm.banAnchorPop = true
                      },
                    },
                  },
                  [_vm._v("封停直播")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.back } }, [_vm._v("返回")]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "封停直播", visible: _vm.banAnchorPop },
          on: {
            "update:visible": function ($event) {
              _vm.banAnchorPop = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "6px" },
              attrs: {
                model: _vm.noLiveForm,
                rules: _vm.rules,
                size: "small",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "封停原因", prop: "cause" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.noLiveForm.cause,
                        callback: function ($$v) {
                          _vm.$set(_vm.noLiveForm, "cause", $$v)
                        },
                        expression: "noLiveForm.cause",
                      },
                    },
                    _vm._l(_vm.causeArr, function (item) {
                      return _c("el-option", {
                        key: item.reasonCode,
                        attrs: {
                          label: item.reasonContent,
                          value: item.reasonCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "禁播时间", prop: "noLiveTime" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      model: {
                        value: _vm.noLiveForm.noLiveTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.noLiveForm, "noLiveTime", $$v)
                        },
                        expression: "noLiveForm.noLiveTime",
                      },
                    },
                    _vm._l(_vm.timeArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label:
                            "" +
                            item.duration +
                            (item.unit == 2 ? "小时" : "分钟"),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "left-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.banAnchorPop = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.banAnchorBtn,
                  },
                  on: { click: _vm.banAnchor },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "关闭直播", visible: _vm.closeLiveRoomPop },
          on: {
            "update:visible": function ($event) {
              _vm.closeLiveRoomPop = $event
            },
          },
        },
        [
          _c("div", { staticClass: "tips" }, [
            _vm._v("确认关闭该直播间吗。关闭后，该主播将在1小时内不能再直播！"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "left-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.closeLiveRoomPop = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("确定"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }