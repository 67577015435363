var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "冻结原因", prop: "freezeReason" } },
        [
          _c("el-input", {
            model: {
              value: _vm.ruleForm.freezeReason,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "freezeReason", $$v)
              },
              expression: "ruleForm.freezeReason",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "冻结时长", prop: "freezeDuration" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "flex-box",
              on: { change: _vm.validateAppoint },
              model: {
                value: _vm.chanceRadio,
                callback: function ($$v) {
                  _vm.chanceRadio = $$v
                },
                expression: "chanceRadio",
              },
            },
            [
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("永久")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("指定")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "number" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("分钟")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.ruleForm.freezeDuration,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "freezeDuration", $$v)
                  },
                  expression: "ruleForm.freezeDuration",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "btn-box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingBtn },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确认")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }