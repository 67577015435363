var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("订单基础信息")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig,
              formData: _vm.formData,
              flexCell: "3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      ![0, 1, 9].includes(_vm.formData.status)
        ? _c(
            "el-card",
            { staticClass: "box-card box_style" },
            [
              _c("h1", { staticClass: "section-title" }, [_vm._v("物流查询")]),
              _vm._v(" "),
              _c("PublicDetailForm", {
                attrs: {
                  formConfig: _vm.formConfig3,
                  formData: _vm.formData,
                  flexCell: "3",
                },
              }),
              _vm._v(" "),
              _vm.resultList.length !== 0
                ? _c("LogisticsStep", { attrs: { resultList: _vm.resultList } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card box_style" },
        [
          _c("h1", { staticClass: "section-title" }, [_vm._v("订单商品")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig1,
              formData: _vm.orderProduct,
              flexCell: "3",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card box_style" },
        [
          _c("h1", { staticClass: "section-title" }, [_vm._v("费用明细")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig2,
              formData: _vm.formData,
              flexCell: "3",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }