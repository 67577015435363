"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLogisticsApi = void 0;
exports.giftExportApi = giftExportApi;
exports.giftOrderBatchSendApi = giftOrderBatchSendApi;
exports.giftOrderSendApi = exports.giftOrderListApi = exports.giftOrderInfoApi = exports.giftOrderCancelApi = void 0;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
var _common = require("@/api/common");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 实物礼物订单 列表
 * @param prams
 */
var giftOrderListApi = exports.giftOrderListApi = function giftOrderListApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/giftOrderPageByAdmin',
    method: 'get',
    params: params
  });
};

/**
 * 实物礼物订单 详情
 * @param id
 */
var giftOrderInfoApi = exports.giftOrderInfoApi = function giftOrderInfoApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/orderDetail',
    method: 'get',
    params: params
  });
};

/**
 * 实物礼物订单 发货
 * @param id
 */
var giftOrderSendApi = exports.giftOrderSendApi = function giftOrderSendApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/shipments',
    method: 'post',
    data: data
  });
};

/**
 * 实物礼物 订单导入excel批量发货
 * @param file
 */
function giftOrderBatchSendApi(file) {
  var formData = new FormData();
  formData.append('file', file);
  return (0, _otherRequest.default)({
    url: "/live/admin/api/gift/batchShipments",
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

/**
 * 实物礼物 列表导出
 * @param data
 */
function giftExportApi(data) {
  (0, _common._EXPORTFILE)('/live/admin/api/gift/exportGiftOrder', _otherRequest.default, data, '实物订单列表');
}

/**
 * 实物礼物订单 取消
 * @param data
 */
var giftOrderCancelApi = exports.giftOrderCancelApi = function giftOrderCancelApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/gift/cancel',
    method: 'post',
    data: data
  });
};

/**
 * 根据物流单号查询出物流商
 */
var getLogisticsApi = exports.getLogisticsApi = function getLogisticsApi(orderNo) {
  return (0, _otherRequest.default)({
    url: "/basic/admin/api/publicly/logistics/queryCompanyName/".concat(orderNo),
    method: 'get'
  });
};