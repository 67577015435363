var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:system:agreement:page"],
              expression: "['platform:system:agreement:page']",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "用户协议", name: "UA" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "隐私政策", name: "PP" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "关于我们", name: "AU" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Tinymce", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === "UA",
                expression: "activeName === 'UA'",
              },
            ],
            key: "UA",
            model: {
              value: _vm.ua,
              callback: function ($$v) {
                _vm.ua = $$v
              },
              expression: "ua",
            },
          }),
          _vm._v(" "),
          _c("Tinymce", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === "PP",
                expression: "activeName === 'PP'",
              },
            ],
            key: "PP",
            model: {
              value: _vm.pp,
              callback: function ($$v) {
                _vm.pp = $$v
              },
              expression: "pp",
            },
          }),
          _vm._v(" "),
          _c("Tinymce", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === "AU",
                expression: "activeName === 'AU'",
              },
            ],
            key: "AU",
            model: {
              value: _vm.au,
              callback: function ($$v) {
                _vm.au = $$v
              },
              expression: "au",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "submit" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:system:agreement:edit"],
                      expression: "['platform:system:agreement:edit']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }