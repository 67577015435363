"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DouhuaPlatformIndex',
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    return {
      activeName: 'UA',
      ua: '1',
      pp: '2',
      au: '3'
    };
  },
  mounted: function mounted() {},
  methods: {
    submit: function submit() {}
  }
};