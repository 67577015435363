"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var financeRouter = {
  path: '/finance',
  component: _layout.default,
  redirect: '/finance',
  name: 'Financial',
  meta: {
    title: '财务',
    icon: 'clipboard'
  },
  children: [{
    path: 'statement',
    name: 'FinanceStatement',
    meta: {
      title: '账单管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/statement/index'));
      });
    }
  }, {
    path: 'closingSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/setting/index'));
      });
    },
    name: 'ClosingSetting',
    meta: {
      title: '商户结算设置',
      icon: ''
    }
  }, {
    path: 'closing',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/closing/index'));
      });
    },
    name: 'FinancialClosing',
    meta: {
      title: '结算管理',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'merchantClosing',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/closing/merchantClosing/index'));
        });
      },
      name: 'MerchantClosing',
      meta: {
        title: '商户结算',
        icon: ''
      }
    }, {
      path: 'userClosing',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/closing/userClosing/index'));
        });
      },
      name: 'UserClosing',
      meta: {
        title: '用户结算',
        icon: ''
      }
    }]
  }, {
    path: 'charge',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/charge/index'));
      });
    },
    name: 'FinanceCharge',
    meta: {
      title: '充值记录',
      icon: ''
    }
  }, {
    path: 'journalAccount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/journalAccount/index'));
      });
    },
    name: 'FinanceJournalAccount',
    meta: {
      title: '流水管理',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'capitalFlow',
      name: 'FinanceCapitalFlow',
      meta: {
        title: '资金流水',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/journalAccount/capitalFlow/index'));
        });
      }
    }, {
      path: 'summaryCapitalFlow',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/journalAccount/summaryCapitalFlow/index'));
        });
      },
      name: 'SummaryCapitalFlow',
      meta: {
        title: '流水汇总',
        icon: ''
      }
    }]
  },
  // 用户现金交易
  {
    path: 'userTransaction',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/userTransaction/index'));
      });
    },
    name: 'FinanceuserTransaction',
    meta: {
      title: '用户现金交易',
      icon: ''
    }
  },
  //用户交易详情
  {
    path: 'userTransactionDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/userTransactionDetails/index'));
      });
    },
    name: 'FinanceuserTransactionDetails',
    meta: {
      title: '用户现金交易详情',
      icon: ''
    }
  },
  // 用户钻石交易
  {
    path: 'userDiamondTransaction',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/userDiamondTransaction/index'));
      });
    },
    name: 'userDiamondTransaction',
    meta: {
      title: '用户火星交易明细',
      icon: ''
    }
  },
  // 用户钻石交易详情
  {
    path: 'userDiamondTransactionDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/userDiamondTransactionDetails/index'));
      });
    },
    name: 'userDiamondTransactionDetails',
    meta: {
      title: '用户火星交易详情',
      icon: ''
    }
  },
  // 商户交易
  {
    path: 'merchant',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/merchant/index'));
      });
    },
    name: 'merchant',
    meta: {
      title: '商户交易',
      icon: ''
    }
  },
  //商户交易详情
  {
    path: 'merchantDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/merchantDetails/index'));
      });
    },
    name: 'merchantDetails',
    meta: {
      title: '充值商户交易详情',
      icon: ''
    }
  },
  // 提现管理
  {
    path: 'withdrawalManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/withdrawalManagement/index'));
      });
    },
    name: 'withdrawalManagement',
    meta: {
      title: '提现管理',
      icon: ''
    }
  },
  //提现详情
  {
    path: 'withdrawalDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/withdrawalDetails/index'));
      });
    },
    name: 'withdrawalDetails',
    meta: {
      title: '提现详情',
      icon: ''
    }
  },
  // 火星提现管理
  {
    path: 'marsWithdrawalManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/marsWithdrawalManagement/index'));
      });
    },
    name: 'marsWithdrawalManagement',
    meta: {
      title: '火星提现管理',
      icon: ''
    }
  },
  //火星提现详情
  {
    path: 'marsWithdrawalDetails/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/marsWithdrawalDetails/index'));
      });
    },
    name: 'marsWithdrawalDetails',
    meta: {
      title: '火星提现详情',
      icon: ''
    }
  },
  //钻石收益
  {
    path: 'diamondIncome',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/diamondIncome/index'));
      });
    },
    name: 'diamondIncome',
    meta: {
      title: '钻石收益',
      icon: ''
    }
  },
  //现金收益
  {
    path: 'incomeDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/incomeDetail/index'));
      });
    },
    name: 'incomeDetail',
    meta: {
      title: '现金收益',
      icon: ''
    }
  }]
};
var _default = exports.default = financeRouter;