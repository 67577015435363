"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bloodStatusFilter = bloodStatusFilter;
exports.competenceStatusFilter = competenceStatusFilter;
exports.publishTypeFilter = publishTypeFilter;
exports.shelveStatusFilter = shelveStatusFilter;
/**
 * 发布类型
 */
function publishTypeFilter(status) {
  var statusMap = {
    1: '普通发布',
    2: '涨粉发布'
  };
  return statusMap[status];
}

/**
 * 血条状态
 */
function bloodStatusFilter(status) {
  var statusMap = {
    0: '无',
    1: '进行中',
    2: '已截止',
    3: '已结束'
  };
  return statusMap[status];
}

/**
 * 视频状态
 */
function shelveStatusFilter(status) {
  var statusMap = {
    1: '上架中',
    2: '草稿',
    3: '已下架'
  };
  return statusMap[status];
}

/**
 * 视频权限
 */
function competenceStatusFilter(status) {
  var statusMap = {
    1: '公开',
    2: '好友可见',
    3: '私密作品'
  };
  return statusMap[status];
}