var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("提现详情")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig,
              formData: _vm.formData,
              flexCell: "3",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "withdrawaBtn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.reject } },
                [_vm._v("拒绝 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确认提现 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }