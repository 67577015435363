var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改用户昵称",
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.form, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "昵称：",
                prop: "nickname",
                rules: [
                  { required: true, message: "请输入昵称", trigger: "blur" },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  maxlength: "10",
                  placeholder: "请输入昵称",
                },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }