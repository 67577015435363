var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isAddprizePool ? "增加奖池基数" : "增加作者奖励",
        visible: _vm.value,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.isAddprizePool ? "增加数值" : "奖励数量",
                prop: _vm.isAddprizePool ? "marsAmount" : "rewardsMars",
                rules: _vm.isAddprizePool
                  ? _vm.rules.marsAmount
                  : _vm.rules.rewardsMars,
              },
            },
            [
              !_vm.isAddprizePool
                ? _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入奖励数量" },
                    on: { change: _vm.checkValue },
                    model: {
                      value: _vm.ruleForm.rewardsMars,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rewardsMars", $$v)
                      },
                      expression: "ruleForm.rewardsMars",
                    },
                  })
                : _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入数值" },
                    on: { change: _vm.checkValue },
                    model: {
                      value: _vm.ruleForm.marsAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "marsAmount", $$v)
                      },
                      expression: "ruleForm.marsAmount",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注内容" },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }