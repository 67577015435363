"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shortVideo = require("@/api/shortVideo");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tableHead: [{
        label: '增加基数',
        prop: 'marsValue'
      }, {
        label: '日期',
        prop: 'createTime'
      }, {
        label: '操作者',
        prop: 'createBy'
      }, {
        label: '备注',
        prop: 'remark'
      }],
      tableData: {
        data: [],
        total: 0
      },
      loading: false,
      pagePamars: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  watch: {
    visible: function visible(newVal) {
      if (newVal) this.getList();
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _shortVideo.poolRecordListApi)(this.pagePamars).then(function (res) {
        _this.loading = false;
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    pageChange: function pageChange(val) {
      this.pagePamars.pageNum = val;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pagePamars.pageSize = val;
      this.getList();
    }
  }
};