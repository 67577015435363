var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "消息标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { maxlength: "50", placeholder: "请输入消息标题" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送对象" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.formData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("用户")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("商户")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "具体对象", prop: _vm.specificObjProp } },
                [
                  _vm.formData.type === 1
                    ? _c(
                        "div",
                        { staticClass: "flex-box" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              model: {
                                value: _vm.formData.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "sex", $$v)
                                },
                                expression: "formData.sex",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "全部" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "男" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "女" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("cityCascade", {
                            staticStyle: { width: "240px" },
                            attrs: {
                              cityprops: _vm.cityprops,
                              value: _vm.formData.cityData,
                              gradeType: true,
                            },
                            on: {
                              provincesValueChang: _vm.provincesValueChang,
                              "update:value": function ($event) {
                                _vm.formData.cityData = $event
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "flex-box" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formData.merchantType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "merchantType", $$v)
                                },
                                expression: "formData.merchantType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "全部" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 1, label: "个人商户" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "企业商户" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "240px" },
                              attrs: { multiple: "" },
                              on: { change: _vm.clearMerchantValidate },
                              model: {
                                value: _vm.formData.merchantClassIds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "merchantClassIds",
                                    $$v
                                  )
                                },
                                expression: "formData.merchantClassIds",
                              },
                            },
                            _vm._l(_vm.classifys, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送方式", prop: "sendTime" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeSendType },
                      model: {
                        value: _vm.formData.sendType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "sendType", $$v)
                        },
                        expression: "formData.sendType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("即时发送"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("定时发送"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formData.sendType === 2,
                        expression: "formData.sendType === 2",
                      },
                    ],
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.formData.sendTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sendTime", $$v)
                      },
                      expression: "formData.sendTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeContentType },
                      model: {
                        value: _vm.formData.contentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "contentType", $$v)
                        },
                        expression: "formData.contentType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("外部链接"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("富文本内容"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formData.contentType === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "消息内容编辑",
                        prop: _vm.specificObjProp2,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "500px" },
                          attrs: { placeholder: "请输入链接地址" },
                          model: {
                            value: _vm.formData.forwardUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "forwardUrl", $$v)
                            },
                            expression: "formData.forwardUrl",
                          },
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v("https://"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "消息内容编辑",
                        prop: "contentText",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("Tinymce", {
                        model: {
                          value: _vm.formData.contentText,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "contentText", $$v)
                          },
                          expression: "formData.contentText",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "submit-btn" },
            [
              _vm.$route.params.id !== "add"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handleDel },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }