"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shortVideo = require("@/api/shortVideo");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'addRewardDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    //判断是不是增加奖池基数
    isAddprizePool: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    id: {
      handler: function handler(val) {
        this.ruleForm.videoId = val;
      },
      immediate: true
    },
    isAddprizePool: {
      handler: function handler(val) {
        this.rules.remark[0].required = val ? true : false;
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      ruleForm: {
        rewardsMars: null,
        remark: '',
        videoId: this.id,
        marsAmount: null
      },
      rules: {
        rewardsMars: [{
          required: true,
          message: '请输入奖励数量',
          trigger: 'blur'
        }],
        marsAmount: [{
          required: true,
          message: '请输入奖池基数',
          trigger: 'blur'
        }],
        remark: [{
          required: false,
          message: '请输入备注',
          trigger: 'blur'
        }]
      },
      loading: false
    };
  },
  methods: {
    checkValue: function checkValue(value) {
      var inputText = value;
      var reg = /^\d+$/;
      var targetProperty = this.isAddprizePool ? 'marsAmount' : 'rewardsMars';
      if (inputText.startsWith('-') || !reg.test(inputText)) {
        this.$message.error('只能输入正整数');
        this.ruleForm[targetProperty] = '';
      }
    },
    submit: function submit() {
      var _this = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var request = _this.isAddprizePool ? _shortVideo.addPrizePoolApi : _shortVideo.addRewardApi;
          request(_this.ruleForm).then(function (res) {
            _this.loading = false;
            _this.$message.success('操作成功');
            _this.handleClose();
            _this.ruleForm = {};
            _this.$emit('init');
          }).catch(function (err) {
            console.log(err);
            _this.loading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.$emit('update:value', false);
      this.$emit('init');
    }
  }
};