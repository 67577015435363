"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.forbidLiveDetailApi = forbidLiveDetailApi;
exports.forbidLiveListApi = forbidLiveListApi;
exports.liveCancelSuspendApi = liveCancelSuspendApi;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 封停 列表
 * @param params
 */
function forbidLiveListApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/liveSuspend/suspendLivePage',
    method: 'get',
    params: params
  });
}

/**
 * 封停 详情
 * @param params
 */
function forbidLiveDetailApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/liveSuspend/suspendLiveDetail',
    method: 'get',
    params: params
  });
}

/**
 * 封停 解封
 * @param params
 */
function liveCancelSuspendApi(data) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/liveSuspend/cancelSuspend',
    method: 'post',
    data: data
  });
}