"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showFullScreenLoading = exports.hideFullScreenLoading = void 0;
var _elementUi = require("element-ui");
// 记录请求次数
var loadingRequestCount = 0;
var loading;
function startLoading() {
  loading = _elementUi.Loading.service({
    lock: true,
    spinner: 'el-icon-loading',
    text: '拼命加载中...',
    background: 'rgba(0, 0, 0, 0.5)'
  });
}
function endLoading() {
  // 延迟500ms，防止网速特快加载中画面一闪而过
  setTimeout(function () {
    if (loading) loading.close();
  }, 500);
}
// 打开loading
var showFullScreenLoading = exports.showFullScreenLoading = function showFullScreenLoading() {
  if (loadingRequestCount === 0) {
    startLoading();
  }
  loadingRequestCount++;
};
// 关闭loading
var hideFullScreenLoading = exports.hideFullScreenLoading = function hideFullScreenLoading() {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    endLoading();
  }
};