var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:product:page:list"],
                  expression: "['platform:product:page:list']",
                },
              ],
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container mt-1" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "", size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品搜索：" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "商品ID、商品名称、商户名称",
                            },
                            model: {
                              value: _vm.tableFrom.keywords,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "keywords", $$v)
                              },
                              expression: "tableFrom.keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.tableFrom.categoryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "categoryId", $$v)
                                },
                                expression: "tableFrom.categoryId",
                              },
                            },
                            _vm._l(_vm.shopCategoryListData, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "已上架", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "未上架", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            on: { change: _vm.timeChange },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["platform:product:page:list"],
                                  expression: "['platform:product:page:list']",
                                },
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["platform:product:page:list"],
                                  expression: "['platform:product:page:list']",
                                },
                              ],
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.checkPermi(["platform:product:batch:down"])
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.batchOff },
                    },
                    [_vm._v("批量下架")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.checkPermi(["platform:product:batch:export"])
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.exportsList },
                    },
                    [_vm._v("导出列表")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              Number(_vm.tableFrom.type) < 3
                ? _c("el-table-column", {
                    key: "2",
                    attrs: { type: "selection", width: "55" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tableHead, function (item, idx) {
                return [
                  item.custom
                    ? [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            label: item.label,
                            "min-width": "80",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    item.label === "商品封面"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src: scope.row.image,
                                                "preview-src-list": [
                                                  scope.row.image,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            align: "center",
                            label: item.label,
                            prop: item.prop,
                            "min-width": "150",
                            formatter: item.formatter,
                          },
                        }),
                      ],
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus === 1 &&
                        _vm.checkPermi(["platform:product:audit"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:product:info"],
                                expression: "['platform:product:info']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _vm.checkPermi(["platform:product:force:down"]) &&
                        scope.row.isShow
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.offProductVisible = true),
                                      (_vm.multipleSelection = [scope.row])
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("downProductDialog", {
        attrs: { visible: _vm.offProductVisible, ids: _vm.multipleSelection },
        on: {
          "update:visible": function ($event) {
            _vm.offProductVisible = $event
          },
          init: function ($event) {
            return _vm.getList(1)
          },
        },
      }),
      _vm._v(" "),
      _c("AuditDialog", {
        attrs: { currentRowId: _vm.currentRowId },
        on: {
          subSuccess: function ($event) {
            return _vm.getList(1)
          },
        },
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }