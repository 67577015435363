var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:shortVideo:page:list"],
                  expression: "['platform:shortVideo:page:list']",
                },
              ],
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "FilterHeader",
                { ref: "filterHeader", attrs: { formData: _vm.filterForm } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.reset } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.checkVideo(false)
                    },
                  },
                },
                [_vm._v("批量上架")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:shortVideo:ban"],
                      expression: "['platform:shortVideo:ban']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.checkVideo(true)
                    },
                  },
                },
                [_vm._v("批量下架")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "sort-change": _vm.tableSortChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", fixed: "left" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "55",
                  fixed: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "videoNo",
                  label: "视频编号",
                  width: "100",
                  fixed: "left",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nickname", label: "作者姓名", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "introduction",
                  label: "短视频文案",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(row.introduction))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "level", label: "等级", width: "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "image", label: "视频封面", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: row.image,
                                "preview-src-list": [row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "publishType", label: "发布类型", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("publishTypeFilter")(row.publishType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "城市定位", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              [
                                row.provinceName || "",
                                row.cityName || "",
                                row.districtName || "",
                              ].join("") || "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "browsingCount", label: "浏览数", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "playCount", label: "完播数", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "likeCount", label: "点赞数", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "reviewCount", label: "评论数", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "collectCount", label: "收藏数", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("NumberFilterEmpty")(row.collectCount)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "barrageCount", label: "弹幕数", width: "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "marsAmount",
                  label: "累计涨粉火星充值数",
                  width: "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "platformIncome",
                  label: "平台佣金收入",
                  width: "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveMars",
                  label: "火星已被领取数",
                  width: "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "rewards",
                  label: "作品奖励火星数",
                  width: "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "bloodStatus", label: "血条状态", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm._f("bloodStatusFilter")(row.bloodStatus))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "发布时间", width: "160" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "shelveStatus",
                  label: "视频状态",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("p", { attrs: { type: "info" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("shelveStatusFilter")(row.shelveStatus)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "auditStatus", label: "审核状态", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.auditStatus === 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.auditStatus === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已发布"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.auditStatus === 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未通过"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "260",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:shortVideo:detail"],
                                expression: "['platform:shortVideo:detail']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(row.id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        row.publishType === 2
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:shortVideo:addReward"],
                                    expression:
                                      "['platform:shortVideo:addReward']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.id = row.id),
                                      (_vm.addRewardVisible = true)
                                  },
                                },
                              },
                              [_vm._v("增加奖励")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.publishType === 2
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:shortVideo:endReward"],
                                    expression:
                                      "['platform:shortVideo:endReward']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.stopReward(row.id)
                                  },
                                },
                              },
                              [_vm._v("终止奖励")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.auditStatus === 1 && row.shelveStatus !== 1
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:shortVideo:ban"],
                                    expression: "['platform:shortVideo:ban']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBatch(false, row.id)
                                  },
                                },
                              },
                              [_vm._v("上架")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.auditStatus === 1 && row.shelveStatus !== 3
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:shortVideo:ban"],
                                    expression: "['platform:shortVideo:ban']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBatch(true, row.id)
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.filterForm.pageSize,
              "current-page": _vm.filterForm.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddRewardDialog", {
        attrs: { value: _vm.addRewardVisible, id: _vm.id },
        on: {
          "update:value": function ($event) {
            _vm.addRewardVisible = $event
          },
          init: _vm.updateInfo,
        },
      }),
      _vm._v(" "),
      _c("DownVideoDialog", {
        attrs: {
          visible: _vm.offVideoVisible,
          singleId: _vm.singleId,
          ids: _vm.multipleSelection,
          isSignOffVideo: _vm.isSignOffVideo,
        },
        on: {
          "update:visible": function ($event) {
            _vm.offVideoVisible = $event
          },
          init: _vm.updateInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }