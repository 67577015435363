"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuspendDurationListApi = SuspendDurationListApi;
exports.SuspendReasonListApi = SuspendReasonListApi;
exports.liveRoomInfoApi = liveRoomInfoApi;
exports.liveRoomListApi = liveRoomListApi;
exports.liveSuspendApi = liveSuspendApi;
exports.recommendLiveApi = recommendLiveApi;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 直播间 列表
 * @param params
 */
function liveRoomListApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/live/livePageByAdmin',
    method: 'get',
    params: params
  });
}

/**
 * 直播间 详情
 * @param id
 */
function liveRoomInfoApi(params) {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/live/liveDetail",
    method: 'get',
    params: params
  });
}

/**
 * 直播间 封停
 * @param data
 */
function liveSuspendApi(data) {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/liveSuspend/suspendLive",
    method: 'post',
    data: data
  });
}

/**
 * 直播间 封禁原因列表
 */
function SuspendReasonListApi() {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/liveSuspend/getSuspendReasonConfigs",
    method: 'get'
  });
}

/**
 * 直播间 直播封禁时长配置列表
 */
function SuspendDurationListApi() {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/liveSuspend/getLiveSuspendConfigList",
    method: 'get'
  });
}

/**
 * 直播间 推荐直播
 */
function recommendLiveApi(data) {
  return (0, _otherRequest.default)({
    url: "/live/admin/api/live/recommendLive",
    method: 'post',
    data: data
  });
}