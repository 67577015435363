"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _examineDialog = _interopRequireDefault(require("./examineDialog.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DouhuaPlatformDetail',
  components: {
    ExamineDialog: _examineDialog.default
  },
  data: function data() {
    return {
      detailInfo: {},
      formConfig: [{
        label: '商户类型',
        value: function value(_ref) {
          var type = _ref.type;
          return type === 1 ? '个人' : '企业';
        }
      }, {
        label: '商户名称',
        value: 'name'
      }, {
        label: '所选分类',
        value: 'categoryName'
      }, {
        label: '企业名称',
        value: 'realName'
      }, {
        label: '社会统一信用代码',
        value: 'identityNumber'
      }, {
        label: '企业所在地址',
        value: 'firmAddress'
      }, {
        label: '详细地址',
        value: 'addressDetail'
      }, {
        label: '申请用户',
        value: 'nickname'
      }, {
        label: '手机号码',
        value: 'phone'
      }, {
        label: '申请时间',
        value: 'createTime'
      }],
      aduitDialogVisible: false
    };
  },
  mounted: function mounted() {
    var getMerchantRow = JSON.parse(localStorage.getItem('merchatRow'));
    this.detailInfo = getMerchantRow;
    var firmAddress = this.detailInfo.province + this.detailInfo.city + this.detailInfo.district;
    this.$set(this.detailInfo, 'firmAddress', firmAddress);
  },
  methods: {
    getInfo: function getInfo() {
      //需要详情接口
    },
    goBack: function goBack() {
      this.$router.push('/merchant/application');
    }
  }
};