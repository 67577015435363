var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:product:reply:list"],
                  expression: "['platform:product:reply:list']",
                },
              ],
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "tableFrom",
                      attrs: {
                        inline: "",
                        model: _vm.tableFrom,
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mr10", attrs: { label: "搜索：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              placeholder: "回放编号、直播间编号、主播姓名",
                              size: "small",
                            },
                            model: {
                              value: _vm.tableFrom.keyword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "keyword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr10",
                          attrs: { label: "直播开始时间：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                            },
                            on: { change: _vm.changeLiveBeginVal },
                            model: {
                              value: _vm.liveBeginVal,
                              callback: function ($$v) {
                                _vm.liveBeginVal = $$v
                              },
                              expression: "liveBeginVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", align: "center" },
              }),
              _vm._v(" "),
              _vm._l(_vm.tableHead, function (item, idx) {
                return [
                  !item.custom
                    ? [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: {
                            prop: item.prop,
                            label: item.label,
                            align: "center",
                            width: item.width,
                          },
                        }),
                      ]
                    : [
                        _c("el-table-column", {
                          key: item.prop + idx,
                          attrs: { label: item.label, prop: item.prop },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.productName
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "demo-image__preview acea-row row-middle",
                                          },
                                          [
                                            _c("el-image", {
                                              staticClass: "mr10",
                                              staticStyle: {
                                                width: "30px",
                                                height: "30px",
                                              },
                                              attrs: {
                                                src: scope.row.productImage,
                                                "preview-src-list": [
                                                  scope.row.productImage,
                                                ],
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "info" }, [
                                              _vm._v(
                                                _vm._s(scope.row.productName)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:product:reply:delete"],
                                expression: "['platform:product:reply:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row.liveId)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handlePageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }