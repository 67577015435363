"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formData: {
        serialNumber: '128990782228989',
        orderAmount: '99.99',
        payType: 1,
        //支付宝
        type: '1',
        // 普通发布
        payState: 1,
        //已支付
        playNum: 12345,
        serialUser: '张三',
        IncomeObject: 1,
        //商户
        merchantName: '宜家',
        platformMoney: '33.33',
        payTime: '2023-03-01 10:20:30'
      },
      formConfig: [{
        label: '交易类型',
        value: function value(_ref) {
          var type = _ref.type;
          return type === 1 ? '商品订单' : '充值火星';
        }
      }, {
        label: '交易订单编号',
        value: 'serialNumber'
      }, {
        label: '火星金额',
        value: 'orderAmount'
      },
      /**火星金额替换成钻石数（还未确认）
       * { label: '钻石数', value: 'orderAmount' },
       */
      {
        label: '支付方式',
        value: function value(_ref2) {
          var payType = _ref2.payType;
          return payType === 1 ? '支付宝' : '微信';
        }
      }, {
        label: '支付状态',
        value: function value(_ref3) {
          var payState = _ref3.payState;
          return payState === 1 ? '已支付' : '未支付';
        }
      }, {
        label: '支付时间',
        value: 'payTime'
      }, {
        label: '支付流水号',
        value: 'playNum'
      }, {
        label: '交易用户',
        value: 'serialUser'
      }, {
        label: '收入对象',
        value: function value(_ref4) {
          var IncomeObject = _ref4.IncomeObject;
          return IncomeObject === 1 ? '商户' : '平台';
        }
      }, {
        label: '商户名称',
        value: 'merchantName'
      }, {
        label: '平台佣金',
        value: 'platformMoney'
      }]
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.push('/finance/userTransaction');
    }
  }
};