var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:product:reply:list"],
                  expression: "['platform:product:reply:list']",
                },
              ],
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: true, size: "small" } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mr10", attrs: { label: "搜索：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "直播间ID、直播姓名" },
                            model: {
                              value: _vm.tableFrom.keyword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "keyword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mr10", attrs: { label: "直播模式：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.tableFrom.liveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "liveType", $$v)
                                },
                                expression: "tableFrom.liveType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "视频直播", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "语聊房", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mr10", attrs: { label: "是否收费：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.tableFrom.enablePay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "enablePay", $$v)
                                },
                                expression: "tableFrom.enablePay",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "否", value: "0" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "定位筛选：" } },
                        [
                          _c("cityCascade", {
                            attrs: {
                              cityprops: _vm.cityprops,
                              value: _vm.cityDataIds,
                            },
                            on: {
                              provincesValueChang: _vm.provincesValueChang,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.reset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", type: "index" },
              }),
              _vm._v(" "),
              _vm._l(_vm.tableHead, function (item, idx) {
                return [
                  [
                    _c("el-table-column", {
                      key: item.prop + idx,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        formatter: item.formatter,
                      },
                    }),
                  ],
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row.liveId)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRecommend(scope.row.liveId)
                              },
                            },
                          },
                          [_vm._v("推荐")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }