var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "demo-form-inline",
      attrs: {
        inline: true,
        model: _vm.formData,
        "label-width": "100px",
        size: "small",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "搜索：" } },
        [
          _c("el-input", {
            attrs: {
              placeholder:
                "被举报用户、被举报账号、作品ID/直播间ID、举报人、账号",
            },
            model: {
              value: _vm.formData.keyword,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "keyword", $$v)
              },
              expression: "formData.keyword",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "投诉/举报类型:", "label-width": "125px" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.formData.type,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "type", $$v)
                },
                expression: "formData.type",
              },
            },
            [
              _c("el-option", { attrs: { label: "短视频", value: "1" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "直播间", value: "2" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "用户", value: "3" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "投诉/举报内容：", "label-width": "125px" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.formData.complaintsContext,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "complaintsContext", $$v)
                },
                expression: "formData.complaintsContext",
              },
            },
            _vm._l(_vm.complaintList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.label, value: item.label },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "举报日期：" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd HH:mm:ss",
            },
            on: { change: _vm.changeTime },
            model: {
              value: _vm.timerVal,
              callback: function ($$v) {
                _vm.timerVal = $$v
              },
              expression: "timerVal",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }