var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["upload-mask", _vm.showDeleteIcon ? "upload-mask-bgColor" : ""],
      on: {
        mouseenter: function ($event) {
          _vm.showDeleteIcon = true
        },
        mouseleave: function ($event) {
          _vm.showDeleteIcon = false
        },
      },
    },
    [
      _c("div", { ref: "yyevaContainer", staticClass: "yyeva" }),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDeleteIcon,
            expression: "showDeleteIcon",
          },
        ],
        staticClass: "el-icon-delete delete-icon",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleRemove($event)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }