"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FilterHeader',
  props: {
    formData: {
      type: Object,
      require: true
    }
  },
  data: function data() {
    return {
      types: [{
        value: 1,
        label: '注销进行中'
      }, {
        value: 2,
        label: '注销失败'
      }, {
        value: 3,
        label: '成功注销'
      }],
      timerVal: []
    };
  },
  mounted: function mounted() {},
  methods: {
    changeTime: function changeTime(val) {
      this.formData.beginDate = val[0];
      this.formData.endDate = val[1];
    }
  }
};