"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'merchant',
  data: function data() {
    return {
      userFrom: {},
      listLoading: false,
      selectedData: [],
      timeVal: [],
      selectionList: [],
      labelLists: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '商品订单'
      }, {
        id: 2,
        name: '售后退款'
      }, {
        id: 3,
        name: '提现支出'
      }],
      tableData: [{
        id: 1,
        type: 2,
        money: 88.88,
        nowNumer: '1234543255565555',
        payStatus: 1,
        payTime: '2024-1-2',
        payNum: '22222222333',
        payUser: '张三',
        nowUser: '嘻嘻有限公司',
        nowMoney: '22.22',
        payWay: 'bank'
      }],
      registerTypeList: [],
      pickerOptions: this.$timeOptions,
      keywords: '',
      labelData: null
    };
  },
  methods: {
    pageChange: function pageChange() {},
    handleSizeChange: function handleSizeChange() {},
    userSearchs: function userSearchs() {},
    reset: function reset() {},
    onchangeTime: function onchangeTime() {},
    getList: function getList() {},
    deriveBtn: function deriveBtn() {},
    detailBtn: function detailBtn() {}
  }
};