var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "giftForm",
              attrs: {
                model: _vm.giftForm,
                rules: _vm.rules,
                "label-width": "120px",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "礼物名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "selWidth",
                    attrs: { maxlength: "10", placeholder: "请输入" },
                    model: {
                      value: _vm.giftForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.giftForm, "name", $$v)
                      },
                      expression: "giftForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.giftForm.giftType,
                        callback: function ($$v) {
                          _vm.$set(_vm.giftForm, "giftType", $$v)
                        },
                        expression: "giftForm.giftType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("常规礼物"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("实物礼物"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      change: "selWidth",
                      placeholder: "请输入排序",
                      min: 0,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.giftForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.giftForm, "sort", _vm._n($$v))
                      },
                      expression: "giftForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "礼物图片", prop: "image" } },
                [
                  _c("directUpload", {
                    attrs: {
                      model: "img",
                      limit: 1,
                      size: 2,
                      "file-list": _vm.giftForm.image,
                      customFileType: "image/png",
                    },
                    on: {
                      "update:fileList": function ($event) {
                        return _vm.$set(_vm.giftForm, "image", $event)
                      },
                      "update:file-list": function ($event) {
                        return _vm.$set(_vm.giftForm, "image", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "礼物特效" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _vm.yyEvaUrl && _vm.$route.name === "giftDetail"
                      ? _c("YyEvaVideo", {
                          attrs: { url: _vm.yyEvaUrl },
                          on: { delGift: _vm.delGift },
                        })
                      : _c("directUpload", {
                          attrs: {
                            limit: 1,
                            "file-list": _vm.giftForm.specialEffect,
                            model: "video",
                          },
                          on: {
                            "update:fileList": function ($event) {
                              return _vm.$set(
                                _vm.giftForm,
                                "specialEffect",
                                $event
                              )
                            },
                            "update:file-list": function ($event) {
                              return _vm.$set(
                                _vm.giftForm,
                                "specialEffect",
                                $event
                              )
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "礼物价值", prop: "marsAmount" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { type: "number", placeholder: "请输入" },
                      model: {
                        value: _vm.giftForm.marsAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.giftForm, "marsAmount", $$v)
                        },
                        expression: "giftForm.marsAmount",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("火星")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "礼物价格", prop: "showPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { type: "number", placeholder: "请输入" },
                      model: {
                        value: _vm.giftForm.showPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.giftForm, "showPrice", $$v)
                        },
                        expression: "giftForm.showPrice",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "平台佣金比例", prop: "platformRatio" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.giftForm.platformRatio,
                        callback: function ($$v) {
                          _vm.$set(_vm.giftForm, "platformRatio", $$v)
                        },
                        expression: "giftForm.platformRatio",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("%")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.giftForm.giftType === 2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "重量（KG）", prop: "weight" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { type: "number", placeholder: "请输入" },
                              model: {
                                value: _vm.giftForm.weight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.giftForm, "weight", $$v)
                                },
                                expression: "giftForm.weight",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "运费模版",
                            prop: "shippingTemplateId",
                          },
                        },
                        [
                          _c("scrollSelect", {
                            attrs: {
                              list: _vm.guaranteeList,
                              total: _vm.total,
                            },
                            on: { loadNextPage: _vm.getGuaranteeList },
                            model: {
                              value: _vm.giftForm.shippingTemplateId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.giftForm,
                                  "shippingTemplateId",
                                  $$v
                                )
                              },
                              expression: "giftForm.shippingTemplateId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-box" },
                [
                  _vm.id !== "add"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:live:gift:disabled"],
                              expression: "['platform:live:gift:disabled']",
                            },
                          ],
                          staticClass: "btn1",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleDisabled },
                        },
                        [_vm._v("禁用")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.id !== "add"
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:live:gift:delete"],
                              expression: "['platform:live:gift:delete']",
                            },
                          ],
                          staticClass: "btn1",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:live:gift:edit"],
                          expression: "['platform:live:gift:edit']",
                        },
                      ],
                      staticClass: "btn1",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitGift },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }