"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var liveManageRouter = {
  path: '/liveManage',
  component: _layout.default,
  redirect: '/liveManage/list',
  name: 'liveManage',
  meta: {
    title: '直播管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    name: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/liveList/index'));
      });
    },
    meta: {
      title: '直播间列表',
      icon: ''
    }
  }, {
    path: 'forbidLiveList',
    name: 'forbidLiveList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/forbidLiveList/index'));
      });
    },
    meta: {
      title: '封停直播间列表',
      icon: ''
    }
  }, {
    path: 'forbidLiveDetail/:id',
    name: 'forbidLiveDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/forbidLiveList/forbidLiveDetail.vue'));
      });
    },
    meta: {
      title: '封停直播间详情',
      icon: ''
    }
  }, {
    path: 'replayList',
    name: 'replayList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/replayList'));
      });
    },
    meta: {
      title: '回放列表',
      icon: ''
    }
  }, {
    path: 'replayDetail/:id',
    name: 'replayDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/replayDetail'));
      });
    },
    meta: {
      title: '回放详情',
      icon: ''
    }
  }, {
    path: 'liveRoomDetail/:id',
    name: 'LiveRoomDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/liveRoomDetail/index'));
      });
    },
    meta: {
      title: '直播间详情',
      icon: ''
    }
  }, {
    path: 'giftList',
    name: 'giftList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/giftList'));
      });
    },
    meta: {
      title: '礼物管理',
      icon: ''
    }
  }, {
    path: 'giftDetail/:id',
    name: 'giftDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/liveManage/giftDetail'));
      });
    },
    meta: {
      title: '礼物详情',
      icon: ''
    }
  }]
};
var _default = exports.default = liveManageRouter;