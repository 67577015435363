var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:shortVideo:page:list"],
              expression: "['platform:shortVideo:page:list']",
            },
          ],
          ref: "headerBox",
          staticClass: "clearfix",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c(
            "FilterHeader",
            { ref: "filterHeader", attrs: { formData: _vm.filterForm } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "用户昵称", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userImage", label: "头像", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: {
                        src: row.userImage,
                        "preview-src-list": [row.userImage],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "手机号码", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "reason", label: "注销原因", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "注销时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "注销状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("writerOffStatus")(row.status)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [15, 30, 45, 60],
          "page-size": _vm.limit,
          "current-page": _vm.page,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.tableData.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }