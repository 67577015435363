var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("原商户信息")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-wrap" },
            _vm._l(_vm.ordConfig, function (item, idx) {
              return _c("div", { key: idx, staticClass: "flex-wrap-item" }, [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v("\n          " + _vm._s(item.value) + "\n        "),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("新商户信息")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-wrap" },
            _vm._l(_vm.newConfig, function (item, idx) {
              return _c("div", { key: idx, staticClass: "flex-wrap-item" }, [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: ["item-value", { "item-color": item.modify }] },
                  [_vm._v("\n          " + _vm._s(item.value) + "\n        ")]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("原证件信息")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-imgs" },
            _vm._l(_vm.ordIdentifyConfig, function (item, idx) {
              return _c("div", { key: idx }, [
                !(
                  item.label === "营业执照" &&
                  _vm.detailInfo.modifyList[1].oldValue === 1
                )
                  ? _c("div", { staticClass: "flex-img-item" }, [
                      _c("p", [_vm._v(_vm._s(item.label))]),
                      _vm._v(" "),
                      _c("img", { attrs: { src: item.value, alt: "" } }),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("新证件信息")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-imgs" },
            _vm._l(_vm.newIdentifyConfig, function (item, idx) {
              return _c("div", { key: idx }, [
                !(
                  item.label === "营业执照" &&
                  _vm.detailInfo.modifyList[1].newValue === 1
                )
                  ? _c("div", { staticClass: "flex-img-item" }, [
                      _c("p", { class: { "item-color": item.modify } }, [
                        _vm._v(_vm._s(item.label) + " "),
                        item.label ? _c("span", [_vm._v("(更新)")]) : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("img", { attrs: { src: item.value, alt: "" } }),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _vm.detailInfo.auditStatus === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:merchant:update:examine"],
                          expression: "['platform:merchant:update:examine']",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          ;(_vm.dialogVisible = true),
                            (_vm.currentRow = _vm.detailInfo)
                        },
                      },
                    },
                    [_vm._v("审核")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ExamineDialog", {
        attrs: { currentRow: _vm.currentRow },
        on: { subSuccess: _vm.getData },
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }