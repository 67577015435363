var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-select" },
    [
      _c(
        "el-select",
        {
          directives: [
            {
              name: "selectScroll",
              rawName: "v-selectScroll",
              value: _vm.handScoll,
              expression: "handScoll",
            },
          ],
          ref: "p-select",
          staticClass: "el-select-dropdown__wrap",
          attrs: { clearable: "", placeholder: "请选择" },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        _vm._l(_vm.showList, function (ot, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: ot.name, value: ot.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }