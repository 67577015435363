"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.liveHistoryDetailApi = liveHistoryDetailApi;
exports.liveHistoryListApi = liveHistoryListApi;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 直播间 回放列表
 * @param params
 */
function liveHistoryListApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/live/liveHistoryPageByAdmin',
    method: 'get',
    params: params
  });
}

/**
 * 直播间 回放详情
 * @param params
 */
function liveHistoryDetailApi(params) {
  return (0, _otherRequest.default)({
    url: '/live/admin/api/live/liveHistoryDetailByAdmin',
    method: 'get',
    params: params
  });
}