var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isCreate === 0 ? "添加服务条款" : "编辑服务条款",
        visible: _vm.visible,
        width: "50%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务条款", prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: "100", placeholder: "请输入服务条款名称" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务内容描述", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "100",
                  placeholder: "服务内容描述",
                },
                model: {
                  value: _vm.formData.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "content", $$v)
                  },
                  expression: "formData.content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "服务条款图标", prop: "icon" } },
            [
              _c("directUpload", {
                attrs: {
                  model: "img",
                  limit: 1,
                  "file-list": _vm.formData.icon,
                },
                on: {
                  "update:fileList": function ($event) {
                    return _vm.$set(_vm.formData, "icon", $event)
                  },
                  "update:file-list": function ($event) {
                    return _vm.$set(_vm.formData, "icon", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s("(100*100px)"))]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "排序" },
                model: {
                  value: _vm.formData.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "sort", $$v)
                  },
                  expression: "formData.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loadingBtn,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("确认" + _vm._s(_vm.isCreate === 0 ? "新增" : "修改"))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }