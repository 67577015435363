"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'p-select',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: String | Number,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      currPage: 1,
      showList: this.list,
      selectValue: this.value
    };
  },
  watch: {
    list: function list(newVal) {
      this.loading = true;
      this.showList = newVal;
    },
    value: function value(newVal) {
      this.selectValue = newVal;
    }
  },
  methods: {
    handleChange: function handleChange(newValue) {
      this.$emit('input', newValue);
    },
    //监听下拉框的显示和隐藏
    // handleVisibleChange(visible) {
    //   if (!visible) {
    //     this.currPage = 1;
    //     console.log('visible');
    //     this.$emit("loadNextPage", this.currPage);
    //   }
    // },
    handScoll: function handScoll() {
      if (this.showList.length !== this.total) {
        this.currPage++;
        this.$emit('loadNextPage', this.currPage);
      }
    }
  }
};