var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("div", { staticClass: "merchant-info" }, [
            _c(
              "div",
              { staticClass: "merchant-img" },
              [
                _c("el-image", {
                  attrs: { lazy: true, src: _vm.detailInfo.avatar },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-wrap" },
              _vm._l(_vm.formConfig, function (item, idx) {
                return _c("div", { key: idx, staticClass: "flex-wrap-item" }, [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          typeof item.value === "function"
                            ? item.value(_vm.detailInfo)
                            : _vm.detailInfo[item.value]
                        ) +
                        "\n          "
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "merchant-balance-type" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "flex" }, [
                _c("p", { staticClass: "title" }, [_vm._v("入驻分类")]),
                _vm._v(" "),
                _c("p", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.detailInfo.categoryName)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "span",
                    {
                      staticClass: "record-title",
                      on: {
                        click: function ($event) {
                          ;(_vm.recordVisible = true), (_vm.recordNum = 2)
                        },
                      },
                    },
                    [_vm._v("修改记录")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog },
                    },
                    [_vm._v("修改分类")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "merchant-product" },
            [
              _c("h1", { staticClass: "section-title" }, [_vm._v("TA的商品")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": true,
                    "header-cell-style": { fontWeight: "bold" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", type: "index", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "商品ID", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "name",
                      "min-width": "180",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属分类",
                      prop: "categoryName",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("filterEmpty")(row.categoryName))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品价格",
                      prop: "price",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "库存", prop: "stock", "min-width": "70" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.isShow
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("上架中"),
                                ])
                              : _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("已下架"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:product:info"],
                                    expression: "['platform:product:info']",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _vm.detailInfo.status
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:merchant:freeze"],
                          expression: "['platform:merchant:freeze']",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.freezeDialogVisible = true
                        },
                      },
                    },
                    [_vm._v("冻结")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isOperateMoney ? "账户余额" : "修改分类",
            width: "500px",
            "before-close": _vm.handleClose,
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.account,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _vm.isOperateMoney
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "修改余额", prop: "accountMoney" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入账户余额" },
                            model: {
                              value: _vm.account.accountMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.account, "accountMoney", $$v)
                              },
                              expression: "account.accountMoney",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.account.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.account, "type", $$v)
                                },
                                expression: "account.type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "add" } }, [
                                _vm._v("增加"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "sub" } }, [
                                _vm._v("减少"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "商户分类", prop: "merchantCategory" } },
                    [
                      _c("scrollSelect", {
                        attrs: {
                          list: _vm.merchantClassify,
                          total: _vm.categoryTotal,
                        },
                        on: { loadNextPage: _vm.getAllCategoryList },
                        model: {
                          value: _vm.account.merchantCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.account, "merchantCategory", $$v)
                          },
                          expression: "account.merchantCategory",
                        },
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.account.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.account, "remark", $$v)
                      },
                      expression: "account.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.handleOperate },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("OperateRecord", {
        attrs: { visible: _vm.recordVisible, recordNum: _vm.recordNum },
        on: {
          "update:visible": function ($event) {
            _vm.recordVisible = $event
          },
          init: function ($event) {
            ;(_vm.recordVisible = false), (_vm.recordNum = 1)
          },
        },
      }),
      _vm._v(" "),
      _c("FreezeDialog", {
        attrs: { visible: _vm.freezeDialogVisible, merchantId: Number(_vm.id) },
        on: {
          "update:visible": function ($event) {
            _vm.freezeDialogVisible = $event
          },
          init: function ($event) {
            return _vm.getMerchantInfo(_vm.id)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }