var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "取消订单",
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.close,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.formData, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "取消原因:",
                prop: "reason",
                rules: [
                  {
                    required: true,
                    message: "请输入取消原因",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", autocomplete: "off" },
                model: {
                  value: _vm.formData.reason,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "reason",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }