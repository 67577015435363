var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "冻结账号",
        visible: _vm.visible,
        width: "600px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "冻结原因", prop: "freezeReason" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.freezeReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "freezeReason", $$v)
                  },
                  expression: "ruleForm.freezeReason",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "冻结时长", prop: "freezeDuration" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.ruleForm.freezeDuration,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "freezeDuration", $$v)
                    },
                    expression: "ruleForm.freezeDuration",
                  },
                },
                _vm._l(_vm.timeList, function (item, index) {
                  return _c("el-option", {
                    key: item,
                    attrs: {
                      label:
                        index !== _vm.timeList.length - 1
                          ? item + "天"
                          : "永久",
                      value: item,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }