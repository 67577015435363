var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "videoNo", label: "编号", "min-width": "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "作者名称", "min-width": "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "introduction",
              label: "短视频文案",
              "min-width": "130",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "视频", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "demo-image__preview",
                        on: {
                          click: function ($event) {
                            return _vm.previewVideo(scope.row.videoUrl)
                          },
                        },
                      },
                      [
                        scope.row.image
                          ? _c("img", {
                              staticStyle: {
                                width: "100px",
                                height: "80px",
                                cursor: "pointer",
                              },
                              attrs: { src: scope.row.image },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "browsingCount",
              label: "浏览数",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "likeCount", label: "点赞数", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "reviewCount", label: "评论数", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "barrageCount",
              label: "弹幕数",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "shareCount", label: "分享数", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "发布时间",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "shelveStatus",
              label: "视频状态",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("shelveStatusFilter")(scope.row.shelveStatus)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "130",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.tovideoDetail(row.id)
                          },
                        },
                      },
                      [_vm._v(" 详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        attrs: {
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.taWorkParams.pageSize,
          "current-page": _vm.taWorkParams.pageNum,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.tableData.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.pageChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频播放",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-box" }, [
            _c("video", {
              staticStyle: { width: "50%", height: "50%:margin:auto" },
              attrs: { src: _vm.videoUrl, controls: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }