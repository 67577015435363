"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FilterHeader',
  props: {
    formData: {
      type: Object,
      require: true
    }
  },
  data: function data() {
    return {
      timerVal: [],
      complaintList: [{
        label: '色情低俗'
      }, {
        label: '违法犯罪'
      }, {
        label: '历史虚无主义'
      }, {
        label: '辱骂、引战、宣扬仇恨'
      }, {
        label: '引导私下交易'
      }, {
        label: '涉政不当言论'
      }, {
        label: '不实信息'
      }, {
        label: 'AI生成内容问题'
      }, {
        label: '违法违禁'
      }, {
        label: '淫秽色情'
      }, {
        label: '抽烟喝酒'
      }, {
        label: '欺诈/售假'
      }, {
        label: '未成年相关'
      }, {
        label: '危险行为'
      }, {
        label: '录像/盗播'
      }, {
        label: '声音违规'
      }, {
        label: '诱导刷礼'
      }, {
        label: '引导线下转账'
      }, {
        label: '侮辱谩骂'
      }, {
        label: '其他'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    changeTime: function changeTime(val) {
      this.formData.beginTime = val ? val[0] : '';
      this.formData.endTime = val ? val[1] : '';
    }
  }
};