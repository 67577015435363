var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("el-page-header", { on: { back: _vm.goBack } }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("反馈详情")]),
          _vm._v(" "),
          _c("PublicDetailForm", {
            attrs: {
              formConfig: _vm.formConfig,
              formData: _vm.formData,
              flexCell: "3",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex-box" }, [
            _c("div", { staticClass: "label" }, [_vm._v("反馈内容:")]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.formData.feedbackContent))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("图片/视频:")]),
              _vm._v(" "),
              _vm._l(_vm.formData.attachments, function (item) {
                return _c(
                  "div",
                  { key: item.id },
                  [
                    item.type === 1
                      ? _c("el-image", {
                          staticClass: "img",
                          attrs: {
                            src: item.url,
                            "preview-src-list": [item.url],
                          },
                        })
                      : _c("video", {
                          staticClass: "video",
                          attrs: { src: item.url, controls: "" },
                        }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c("h1", { staticClass: "section-title" }, [_vm._v("回复内容")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "textarea", rows: 8, placeholder: "请输入内容" },
            model: {
              value: _vm.formData.replyContent,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "replyContent", $$v)
              },
              expression: "formData.replyContent",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "submit" },
            [
              _vm.formData.status === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:system:feedback:reply"],
                          expression: "['platform:system:feedback:reply']",
                        },
                      ],
                      attrs: { loading: _vm.loadingBtn, type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }