var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-drawer",
        {
          attrs: {
            title: "运费模板",
            visible: _vm.dialogVisible,
            size: "1000px",
            direction: "rtl",
            "before-close": _vm.handleClose,
            "append-to-body": true,
            "modal-append-to-body": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-drawer__content" },
            [
              _vm.dialogVisible
                ? _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        "label-width": "100px",
                        size: "mini",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模板名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "withs",
                            attrs: { placeholder: "请输入模板名称" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "运费设置", prop: "appoint" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.appoint,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "appoint", $$v)
                                },
                                expression: "ruleForm.appoint",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全国包邮"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("部分包邮"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("自定义"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      Number(_vm.ruleForm.appoint) > 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "计件方式", prop: "type" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeRadio(
                                            _vm.ruleForm.type
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "type", $$v)
                                        },
                                        expression: "ruleForm.type",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("按件数"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("按重量"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "运费", prop: "region" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.listLoading,
                                          expression: "listLoading",
                                        },
                                      ],
                                      staticClass: "tempBox",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.ruleForm.region,
                                        border: "",
                                        fit: "",
                                        "highlight-current-row": "",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "送达到",
                                          "min-width": "260",
                                          prop: "city_ids",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.$index === 0 &&
                                                  _vm.ruleForm.appoint === 2
                                                    ? _c("span", [
                                                        _vm._v("默认运费"),
                                                      ])
                                                    : _c("el-cascader", {
                                                        ref: "cascader",
                                                        staticStyle: {
                                                          width: "98%",
                                                        },
                                                        attrs: {
                                                          options: _vm.cityList,
                                                          rules:
                                                            _vm.rules.city_ids,
                                                          props: _vm.props,
                                                          filterable: "",
                                                          "collapse-tags": "",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.city_ids,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "city_ids",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.city_ids",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          587726422
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "min-width": "120px",
                                          align: "center",
                                          label: _vm.columns.title,
                                          prop: "first",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules: _vm.rules.first,
                                                        prop:
                                                          "region." +
                                                          scope.$index +
                                                          ".first",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                          "step-strictly":
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? true
                                                              : false,
                                                          min:
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? 1
                                                              : 0.1,
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.first,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "first",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.first",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          180479926
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "min-width": "120px",
                                          align: "center",
                                          label: "运费（元）",
                                          prop: "firstPrice",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules:
                                                          _vm.rules.firstPrice,
                                                        prop:
                                                          "region." +
                                                          scope.$index +
                                                          ".firstPrice",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                          min: 0,
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .firstPrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "firstPrice",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.firstPrice",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1074583209
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "min-width": "120px",
                                          align: "center",
                                          label: _vm.columns.title2,
                                          prop: "renewal",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules:
                                                          _vm.rules.renewal,
                                                        prop:
                                                          "region." +
                                                          scope.$index +
                                                          ".renewal",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                          "step-strictly":
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? true
                                                              : false,
                                                          min:
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? 1
                                                              : 0.1,
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.renewal,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "renewal",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.renewal",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4119314506
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "class-name": "status-col",
                                          align: "center",
                                          label: "续费（元）",
                                          "min-width": "120",
                                          prop: "renewalPrice",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules:
                                                          _vm.rules
                                                            .renewalPrice,
                                                        prop:
                                                          "region." +
                                                          scope.$index +
                                                          ".renewalPrice",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                          min: 0,
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .renewalPrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "renewalPrice",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.renewalPrice",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2052040789
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm.ruleForm.appoint === 1 ||
                                                  (_vm.ruleForm.appoint !== 1 &&
                                                    scope.$index > 0)
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.confirmEdit(
                                                                _vm.ruleForm
                                                                  .region,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  删除\n                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4187321847
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        icon: "el-icon-edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRegion(
                                            _vm.ruleForm.region
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            添加区域\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.appoint === 2
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "包邮区域" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.ruleForm.free,
                                            border: "",
                                            fit: "",
                                            "highlight-current-row": "",
                                            size: "mini",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "选择区域",
                                              "min-width": "220",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c("el-cascader", {
                                                        ref: "cascader",
                                                        staticStyle: {
                                                          width: "95%",
                                                        },
                                                        attrs: {
                                                          options: _vm.cityList,
                                                          props: _vm.props,
                                                          clearable: "",
                                                          "collapse-tags": "",
                                                        },
                                                        model: {
                                                          value: row.city_ids,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "city_ids",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.city_ids",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2427574091
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              "min-width": "180px",
                                              align: "center",
                                              label: _vm.columns.title3,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                          "step-strictly":
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? true
                                                              : false,
                                                          min:
                                                            _vm.ruleForm
                                                              .type === 1
                                                              ? 1
                                                              : 0.1,
                                                        },
                                                        model: {
                                                          value: row.number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "number",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.number",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              361333730
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              "min-width": "120px",
                                              align: "center",
                                              label: "包邮金额（元）",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value: row.price,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "price",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.price",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3238298642
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "操作",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.confirmEdit(
                                                                _vm.ruleForm
                                                                  .free,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  删除\n                "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1898196105
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ruleForm.appoint === 2,
                                      expression: "ruleForm.appoint === 2",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        icon: "el-icon-edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addFree(_vm.ruleForm.free)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            添加区域\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序" } },
                        [
                          _c("el-input", {
                            staticClass: "withs",
                            attrs: { placeholder: "请输入排序" },
                            model: {
                              value: _vm.ruleForm.sort,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "sort",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "demo-drawer__footer from-foot-btn btn-shadow drawer_fix",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("ruleForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:shipping:templates:update"],
                      expression: "['platform:shipping:templates:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }