"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
!function () {
  "use strict";

  var a,
    t,
    c,
    n,
    r,
    i,
    e,
    o,
    s,
    h,
    u,
    f,
    l,
    p,
    g,
    d,
    y,
    S = S || (a = Math, l = (p = {}).lib = {}, t = l.Base = {
      extend: function extend(e) {
        m.prototype = this;
        var t = new m();
        return e && t.mixIn(e), t.hasOwnProperty("init") || (t.init = function () {
          t.$super.init.apply(this, arguments);
        }), (t.init.prototype = t).$super = this, t;
      },
      create: function create() {
        var e = this.extend();
        return e.init.apply(e, arguments), e;
      },
      init: function init() {},
      mixIn: function mixIn(e) {
        for (var t in e) e.hasOwnProperty(t) && (this[t] = e[t]);
        e.hasOwnProperty("toString") && (this.toString = e.toString);
      },
      clone: function clone() {
        return this.init.prototype.extend(this);
      }
    }, c = l.WordArray = t.extend({
      init: function init(e, t) {
        e = this.words = e || [], this.sigBytes = null != t ? t : 4 * e.length;
      },
      toString: function toString(e) {
        return (e || n).stringify(this);
      },
      concat: function concat(e) {
        var t = this.words,
          n = e.words,
          r = this.sigBytes;
        if (e = e.sigBytes, this.clamp(), r % 4) for (var i = 0; i < e; i++) t[r + i >>> 2] |= (n[i >>> 2] >>> 24 - i % 4 * 8 & 255) << 24 - (r + i) % 4 * 8;else if (65535 < n.length) for (i = 0; i < e; i += 4) t[r + i >>> 2] = n[i >>> 2];else t.push.apply(t, n);
        return this.sigBytes += e, this;
      },
      clamp: function clamp() {
        var e = this.words,
          t = this.sigBytes;
        e[t >>> 2] &= 4294967295 << 32 - t % 4 * 8, e.length = a.ceil(t / 4);
      },
      clone: function clone() {
        var e = t.clone.call(this);
        return e.words = this.words.slice(0), e;
      },
      random: function random(e) {
        for (var t = [], n = 0; n < e; n += 4) t.push(4294967296 * a.random() | 0);
        return new c.init(t, e);
      }
    }), s = p.enc = {}, n = s.Hex = {
      stringify: function stringify(e) {
        var t = e.words;
        e = e.sigBytes;
        for (var n = [], r = 0; r < e; r++) {
          var i = t[r >>> 2] >>> 24 - r % 4 * 8 & 255;
          n.push((i >>> 4).toString(16)), n.push((15 & i).toString(16));
        }
        return n.join("");
      },
      parse: function parse(e) {
        for (var t = e.length, n = [], r = 0; r < t; r += 2) n[r >>> 3] |= parseInt(e.substr(r, 2), 16) << 24 - r % 8 * 4;
        return new c.init(n, t / 2);
      }
    }, r = s.Latin1 = {
      stringify: function stringify(e) {
        var t = e.words;
        e = e.sigBytes;
        for (var n = [], r = 0; r < e; r++) n.push(String.fromCharCode(t[r >>> 2] >>> 24 - r % 4 * 8 & 255));
        return n.join("");
      },
      parse: function parse(e) {
        for (var t = e.length, n = [], r = 0; r < t; r++) n[r >>> 2] |= (255 & e.charCodeAt(r)) << 24 - r % 4 * 8;
        return new c.init(n, t);
      }
    }, i = s.Utf8 = {
      stringify: function stringify(e) {
        try {
          return decodeURIComponent(escape(r.stringify(e)));
        } catch (e) {
          throw Error("Malformed UTF-8 data");
        }
      },
      parse: function parse(e) {
        return r.parse(unescape(encodeURIComponent(e)));
      }
    }, e = l.BufferedBlockAlgorithm = t.extend({
      reset: function reset() {
        this._data = new c.init(), this._nDataBytes = 0;
      },
      _append: function _append(e) {
        "string" == typeof e && (e = i.parse(e)), this._data.concat(e), this._nDataBytes += e.sigBytes;
      },
      _process: function _process(e) {
        var t = this._data,
          n = t.words,
          r = t.sigBytes,
          i = this.blockSize,
          o = r / (4 * i),
          o = e ? a.ceil(o) : a.max((0 | o) - this._minBufferSize, 0),
          r = a.min(4 * (e = o * i), r);
        if (e) {
          for (var s = 0; s < e; s += i) this._doProcessBlock(n, s);
          s = n.splice(0, e), t.sigBytes -= r;
        }
        return new c.init(s, r);
      },
      clone: function clone() {
        var e = t.clone.call(this);
        return e._data = this._data.clone(), e;
      },
      _minBufferSize: 0
    }), l.Hasher = e.extend({
      cfg: t.extend(),
      init: function init(e) {
        this.cfg = this.cfg.extend(e), this.reset();
      },
      reset: function reset() {
        e.reset.call(this), this._doReset();
      },
      update: function update(e) {
        return this._append(e), this._process(), this;
      },
      finalize: function finalize(e) {
        return e && this._append(e), this._doFinalize();
      },
      blockSize: 16,
      _createHelper: function _createHelper(n) {
        return function (e, t) {
          return new n.init(t).finalize(e);
        };
      },
      _createHmacHelper: function _createHmacHelper(n) {
        return function (e, t) {
          return new o.HMAC.init(n, t).finalize(e);
        };
      }
    }), o = p.algo = {}, p);
  function m() {}
  function _(e) {
    return encodeURIComponent(e).replace(/!/g, "%21").replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\*/g, "%2A");
  }
  h = (l = (s = S).lib).WordArray, u = l.Hasher, f = [], l = s.algo.SHA1 = u.extend({
    _doReset: function _doReset() {
      this._hash = new h.init([1732584193, 4023233417, 2562383102, 271733878, 3285377520]);
    },
    _doProcessBlock: function _doProcessBlock(e, t) {
      for (var n, r = this._hash.words, i = r[0], o = r[1], s = r[2], a = r[3], c = r[4], h = 0; h < 80; h++) f[h] = h < 16 ? 0 | e[t + h] : (n = f[h - 3] ^ f[h - 8] ^ f[h - 14] ^ f[h - 16]) << 1 | n >>> 31, n = (i << 5 | i >>> 27) + c + f[h], n = h < 20 ? n + (1518500249 + (o & s | ~o & a)) : h < 40 ? n + (1859775393 + (o ^ s ^ a)) : h < 60 ? n + ((o & s | o & a | s & a) - 1894007588) : n + ((o ^ s ^ a) - 899497514), c = a, a = s, s = o << 30 | o >>> 2, o = i, i = n;
      r[0] = r[0] + i | 0, r[1] = r[1] + o | 0, r[2] = r[2] + s | 0, r[3] = r[3] + a | 0, r[4] = r[4] + c | 0;
    },
    _doFinalize: function _doFinalize() {
      var e = this._data,
        t = e.words,
        n = 8 * this._nDataBytes,
        r = 8 * e.sigBytes;
      return t[r >>> 5] |= 128 << 24 - r % 32, t[14 + (64 + r >>> 9 << 4)] = Math.floor(n / 4294967296), t[15 + (64 + r >>> 9 << 4)] = n, e.sigBytes = 4 * t.length, this._process(), this._hash;
    },
    clone: function clone() {
      var e = u.clone.call(this);
      return e._hash = this._hash.clone(), e;
    }
  }), s.SHA1 = u._createHelper(l), s.HmacSHA1 = u._createHmacHelper(l), g = (p = S).enc.Utf8, p.algo.HMAC = p.lib.Base.extend({
    init: function init(e, t) {
      e = this._hasher = new e.init(), "string" == typeof t && (t = g.parse(t));
      var n = e.blockSize,
        r = 4 * n;
      (t = t.sigBytes > r ? e.finalize(t) : t).clamp();
      for (var e = this._oKey = t.clone(), t = this._iKey = t.clone(), i = e.words, o = t.words, s = 0; s < n; s++) i[s] ^= 1549556828, o[s] ^= 909522486;
      e.sigBytes = t.sigBytes = r, this.reset();
    },
    reset: function reset() {
      var e = this._hasher;
      e.reset(), e.update(this._iKey);
    },
    update: function update(e) {
      return this._hasher.update(e), this;
    },
    finalize: function finalize(e) {
      var t = this._hasher;
      return e = t.finalize(e), t.reset(), t.finalize(this._oKey.clone().concat(e));
    }
  }), y = (d = S).lib.WordArray, d.enc.Base64 = {
    stringify: function stringify(e) {
      for (var t = e.words, n = e.sigBytes, r = this._map, i = (e.clamp(), []), o = 0; o < n; o += 3) for (var s = (t[o >>> 2] >>> 24 - o % 4 * 8 & 255) << 16 | (t[o + 1 >>> 2] >>> 24 - (o + 1) % 4 * 8 & 255) << 8 | t[o + 2 >>> 2] >>> 24 - (o + 2) % 4 * 8 & 255, a = 0; a < 4 && o + .75 * a < n; a++) i.push(r.charAt(s >>> 6 * (3 - a) & 63));
      var c = r.charAt(64);
      if (c) for (; i.length % 4;) i.push(c);
      return i.join("");
    },
    parse: function parse(e) {
      for (var t, n, r = e.length, i = this._map, o = i.charAt(64), s = (o && -1 != (o = e.indexOf(o)) && (r = o), []), a = 0, c = 0; c < r; c++) c % 4 && (t = i.indexOf(e.charAt(c - 1)) << c % 4 * 2, n = i.indexOf(e.charAt(c)) >>> 6 - c % 4 * 2, s[a >>> 2] |= (t | n) << 24 - a % 4 * 8, a++);
      return y.create(s, a);
    },
    _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
  };
  function w(e) {
    var t, n, r, i, o, s, a, c, h, u, f, l, p, g, d, y, m, w;
    return e.SecretId ? e.SecretKey ? "4.0" === e.Version ? (n = (t = e).Pathname || "/", r = t.Expires, s = i = "", (o = t.Bucket.match(/^(.+)-(\d+)$/)) && (i = o[1], s = o[2]), o = parseInt(Math.random() * Math.pow(2, 32)), a = parseInt(Date.now() / 1e3), r = a + (void 0 === r ? 900 : +r || 0), n = "/" + s + "/" + i + encodeURIComponent(n).replace(/%2F/g, "/"), s = "a=" + s + "&b=" + i + "&k=" + t.SecretId + "&e=" + r + "&t=" + a + "&r=" + o + "&f=" + n, i = S.HmacSHA1(s, t.SecretKey), r = S.enc.Utf8.parse(s), a = i.concat(r).toString(S.enc.Base64), console.log("sign:", a), o = v(s, t.SecretKey), console.log("res:", o), console.log("sign1:", A(o)), console.log("sign2:", A(s)), console.log("sign2:", A(o + s)), a) : "post-object-policy" === e.Version ? (n = e, i = Math.round(Date.now() / 1e3), r = i + (n.Expires || 900), i = i + ";" + r, r = JSON.stringify({
      expiration: new Date(1e3 * r).toISOString(),
      conditions: [{
        "q-sign-algorithm": "sha1"
      }, {
        "q-ak": n.SecretId
      }, {
        "q-sign-time": i
      }, {
        bucket: n.Bucket
      }, {
        key: n.Key
      }]
    }), t = v(i, n.SecretKey), o = B(r), o = v(o, t), {
      policyObj: JSON.parse(r),
      policy: A(r),
      qSignAlgorithm: "sha1",
      qAk: n.SecretId,
      qKeyTime: i,
      qSignature: o
    }) : (s = (e = e || {}).SecretId, a = e.SecretKey, m = (e.Method || "get").toLowerCase(), c = e.Query || {}, h = e.Headers || {}, w = e.Pathname || "/", e = e.Expires, u = function u(e) {
      var t,
        n = [];
      for (t in e) e.hasOwnProperty(t) && n.push(t);
      return n.sort(function (e, t) {
        return (e = e.toLowerCase()) === (t = t.toLowerCase()) ? 0 : t < e ? 1 : -1;
      });
    }, f = function f(e, t) {
      for (var n, r, i = [], o = u(e), s = 0; s < o.length; s++) r = void 0 === e[n = o[s]] || null === e[n] ? "" : "" + e[n], n = t ? _(n).toLowerCase() : _(n), r = _(r) || "", i.push(n + "=" + r);
      return i.join("&");
    }, l = s, e = p = (p = parseInt(new Date().getTime() / 1e3) - 1) + ";" + (p + (void 0 === e ? 900 : +e || 0)), g = u(h).join(";").toLowerCase(), d = u(c).join(";").toLowerCase(), y = v(e, a), m = [m, w, f(c, !0), f(h, !0), ""].join("\n"), w = ["sha1", p, B(m), ""].join("\n"), ["q-sign-algorithm=sha1", "q-ak=" + l, "q-sign-time=" + p, "q-key-time=" + e, "q-header-list=" + g, "q-url-param-list=" + d, "q-signature=" + v(w, y)].join("&")) : console.error("missing param SecretKey") : console.error("missing param SecretId");
  }
  var v = function v(e, t) {
      return S.HmacSHA1(e, t).toString();
    },
    B = function B(e) {
      return S.SHA1(e).toString();
    },
    A = function A(e) {
      return S.enc.Utf8.parse(e).toString(S.enc.Base64);
    };
  w.HmacSHA1 = v, w.SHA1 = B, w.base64 = A, "object" == (typeof module === "undefined" ? "undefined" : _typeof(module)) ? module.exports = w : window.CosAuth = w;
}();