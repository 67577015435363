var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改等级",
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "gradeForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.gradeForm,
            rules: _vm.gradeRules,
            "label-width": "100px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "当前等级:" } }, [
            _c("span", [_vm._v(_vm._s(_vm.currentGrade))]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "修改等级:", prop: "grade" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择等级", clearable: "" },
                  model: {
                    value: _vm.gradeForm.grade,
                    callback: function ($$v) {
                      _vm.$set(_vm.gradeForm, "grade", $$v)
                    },
                    expression: "gradeForm.grade",
                  },
                },
                _vm._l(_vm.gradeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "编辑备注信息" },
                model: {
                  value: _vm.gradeForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.gradeForm, "remark", $$v)
                  },
                  expression: "gradeForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.gradeConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }