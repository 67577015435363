"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'i-copyright',
  props: {
    copyrightInfo: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      links: [{
        title: '官网',
        key: '1',
        href: 'https://www.crmeb.com',
        blankTarget: true
      }, {
        title: '社区',
        key: '2',
        href: 'https://q.crmeb.net/?categoryId=122&sequence=0',
        blankTarget: true
      }, {
        title: '文档',
        key: '3',
        href: 'https://help.crmeb.net/crmeb_java/1748037',
        blankTarget: true
      }],
      copyright: 'Copyright © 2023 西安众邦网络科技有限公司',
      copyrightNew: ''
    };
  },
  mounted: function mounted() {
    if (this.copyrightInfo) this.copyrightNew = this.copyrightInfo.companyName ? this.copyrightInfo.companyName : this.copyright;
  }
};