"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messageDelApi = messageDelApi;
exports.messageDetailApi = messageDetailApi;
exports.messageListApi = messageListApi;
exports.messagePublishApi = messagePublishApi;
exports.messageUpdateDetailApi = messageUpdateDetailApi;
var _otherRequest = _interopRequireDefault(require("@/utils/otherRequest"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 消息 列表
 */
function messageListApi(params) {
  return (0, _otherRequest.default)({
    url: '/message/admin/api/admin/message/getMessageList',
    method: 'get',
    params: params
  });
}

/**
 * 消息 发布
 */
function messagePublishApi(data) {
  return (0, _otherRequest.default)({
    url: '/message/admin/api/admin/message/publish',
    method: 'post',
    data: data
  });
}

/**
 * 消息 详情
 */
function messageDetailApi(params) {
  return (0, _otherRequest.default)({
    url: '/message/admin/api/admin/message/getDetail',
    method: 'get',
    params: params
  });
}

/**
 * 消息 修改详情
 */
function messageUpdateDetailApi(data) {
  return (0, _otherRequest.default)({
    url: '/message/admin/api/admin/message/updateDetail',
    method: 'post',
    data: data
  });
}

/**
 * 消息 删除
 */
function messageDelApi(params) {
  return (0, _otherRequest.default)({
    url: '/message/admin/api/admin/message/delete',
    method: 'get',
    params: params
  });
}