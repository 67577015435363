"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _downProductDialog = _interopRequireDefault(require("../components/downProductDialog.vue"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    downProductDialog: _downProductDialog.default
  },
  data: function data() {
    return {
      id: null,
      formData: {},
      formConfig: [{
        label: '商品编号',
        value: 'id'
      }, {
        label: '商品名称',
        value: 'name'
      }, {
        label: '所属分类',
        value: 'categoryName'
      }, {
        label: '所属商家',
        value: 'merchantName'
      }, {
        label: '商家类型',
        value: function value(_ref) {
          var merchantType = _ref.merchantType;
          return merchantType === 1 ? '个人' : '企业';
        }
      }, {
        label: '商家分类',
        value: 'merchantCategoryName'
      }, {
        label: '状态',
        value: function value(_ref2) {
          var isShow = _ref2.isShow;
          return isShow ? '已上架' : '已下架';
        }
      }, {
        label: '创建时间',
        value: 'createTime'
      }],
      tableData: [],
      spanArr: [],
      tableBodyList: [],
      multipleSelection: [],
      offProductVisible: false
    };
  },
  created: function created() {
    this.id = this.$route.params.id;
    // this.getSpanArr(this.tableData);
  },
  mounted: function mounted() {
    this.detailInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    goBack: function goBack() {
      this.$router.back();
    },
    detailInfo: function detailInfo() {
      var _this = this;
      (0, _product.productDetailApi)(this.id).then(function (res) {
        _this.formData = res;
        _this.tableBodyList = _this.formData.attrValue.map(function (item) {
          return _objectSpread({
            id: item.id,
            price: item.price,
            stock: item.stock
          }, item.attrValueMap);
        });
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref3) {
      var row = _ref3.row,
        column = _ref3.column,
        rowIndex = _ref3.rowIndex,
        columnIndex = _ref3.columnIndex;
      if (columnIndex === 0) {
        var _row = this.spanArr[rowIndex];
        var col = _row > 0 ? 1 : 0;
        console.log(col, 'col');
        return {
          rowspan: _row,
          //行
          colspan: col //列
        };
      }
    },
    getSpanArr: function getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].id === data[i - 1].id && data[i].id) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      console.log(this.spanArr, ' this.spanArr');
    }
  }
};